import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";

import Header from "../../component/Header";
import { fetchDetailsAction } from "../../state/unit/unit";

import EditUnit from "./editUnit";

const Units = () => {
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);

  const unit = useSelector((state) => state.units.Details);

  useEffect(() => {
    dispatch(fetchDetailsAction());
  }, [dispatch]);

  useEffect(() => {
    if (unit) {
      const data = unit.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [unit]);

  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "unit",
      header: "Unit",
    },
    {
      accessorKey: "deliveryCost",
      header: "Delivery Cost",
    },
    {
      header: "Actions",
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Edit">
            <IconButton
              color="error"
              onClick={() => handleEditDetails(row.original._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const handleEditDetails = (id) => {
    openEditHandler();
    setEditId(id);
  };
  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: dataSource,
    enableSorting: true,
  });

  return (
    <>
      <EditUnit
        handleClose={openEditHandler}
        open={openEditDrawer}
        id={editId}
      />

      <Box m="1.5rem 2.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Pricing Details" subtitle="List of Details" />
        </div>

        <Box mt="40px" height="75vh">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Units;
