import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../component/Header";
import {
  Box,
  Button,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { fetchMapsAction, deleteMapsAction } from "../../state/maps/mapsSlice";
import AddMapForm from "./addMaps";
import EditMap from "./editMap";

const Maps = () => {
  const dispatch = useDispatch();

  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [mapToDelete, setMapToDelete] = useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);

  const mapsData = useSelector((state) => state.maps.Details);

  useEffect(() => {
    dispatch(fetchMapsAction());
  }, [dispatch]);

  const handleDeleteMap = async (id) => {
    await dispatch(deleteMapsAction(id));
    setDeleteConfirmationOpen(false);
    setToastMessage("Map deleted successfully");
    setOpenToast(true);
    dispatch(fetchMapsAction());
  };

  const handleConfirmDelete = (id) => {
    setMapToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setMapToDelete(null);
    setDeleteConfirmationOpen(false);
  };
  const openHandler = () => {
    setOpenDrawer((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };
  const handleEditMap = (id) => {
    openEditHandler();
    setEditId(id);
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };

  return (
    <>
      <AddMapForm handleClose={openHandler} open={openDrawer} />
      <EditMap
        handleClose={openEditHandler}
        open={openEditDrawer}
        id={editId}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>Are you sure you want to delete this map?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteMap(mapToDelete)}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />

      <Box m="1.5rem 2.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Maps" subtitle="List of Maps" />
          <Button
            variant="contained"
            onClick={openHandler}
            color="secondary"
            size="lg"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Map
          </Button>
        </div>
        {mapsData &&
          mapsData.map((map) => (
            <Box key={map._id} mt="40px">
              <h3 style={{ textTransform: "uppercase", textAlign: "left" }}>
                Embed Map
              </h3>
              {map.embedMap && (
                <iframe
                  title="Google Map"
                  src={map.embedMap}
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                ></iframe>
              )}
              {map.directionMap && (
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: 4,
                    p: 2,
                    backgroundColor: "#f0f0f0",
                    boxShadow: 4,
                    marginTop: "2rem",
                  }}
                >
                  <a
                    href={map.directionMap}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }}
                  >
                    Link to Directions Map
                  </a>
                </Paper>
              )}
              <div style={{ textAlign: "left", marginTop: "10px" }}>
                <Button
                  color="error"
                  onClick={() => handleConfirmDelete(map._id)}
                  startIcon={<DeleteIcon />}
                  style={{ marginRight: "10px" }}
                >
                  Delete
                </Button>
                <Button
                  color="secondary"
                  onClick={() => handleEditMap(map._id)}
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </div>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default Maps;
