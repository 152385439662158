import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

import { baseUrl } from "../../utils/config";

export const fetchDetailsAction = createAsyncThunk(
  "dashboard/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/dashboard`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const dashboardSlices = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // details
    builder.addCase(fetchDetailsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDetailsAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
  },
});

export default dashboardSlices.reducer;
