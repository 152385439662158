import React from "react";

const ColorBox = ({ color }) => {
  return (
    <div
      style={{
        width: 30,
        height: 30,
        backgroundColor: color,
        border: "1px solid #ccc",
      }}
    ></div>
  );
};

export default ColorBox;