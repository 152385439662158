import React, { useEffect, useState } from "react";
import { fetchDetailsAction } from "../../state/dashboard/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import {
  FeedbackOutlined,
  ShoppingCartOutlined,
  ReceiptLongOutlined,
  CategoryOutlined,
  MonetizationOnOutlined,
} from "@mui/icons-material";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { CircularProgress } from '@mui/material';

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDetailsAction());
  }, [dispatch]);

  const dashboardData = useSelector((state) => state?.dashboard?.Details);
  console.log(dashboardData);
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [barChartData1, setBarChartData1] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const mostLikedItems = dashboardData?.mostLikedItem || []; // Ensure mostLikedItems is defined
    if (!mostLikedItems) {
      return;
    }
    if (mostLikedItems.length > 0) {
      const data =
        mostLikedItems &&
        mostLikedItems?.map((item) => ({
          label: item?.name || "",
          value: item?.favorites || 0,
        }));

      setPieChartData(data);
    }
  }, [dashboardData?.mostLikedItem]);

  useEffect(() => {
    const mostOrderedItems = dashboardData?.mostOrderedItem || []; // Ensure mostLikedItems is defined

    if (!mostOrderedItems) {
      return;
    }
    if (mostOrderedItems.length > 0) {
      const data =
        mostOrderedItems &&
        mostOrderedItems?.map((item) => ({
          label: item?.name || "",
          value: item?.count || 0,
        }));

      setBarChartData(data);
    }
  }, [dashboardData?.mostOrderedItem]);

  useEffect(() => {
    const ordersLastTenDays = dashboardData?.ordersLastTenDays;

    if (ordersLastTenDays === undefined) {
      return; // Return early if ordersLastTenDays is undefined
    }

    if (ordersLastTenDays && ordersLastTenDays.length > 0) {
      setBarChartData1(ordersLastTenDays);
    }
  }, [dashboardData?.ordersLastTenDays]);
  console.log(barChartData1);
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    margin: "0 auto",
  };

  const boxStyle = {
    textAlign: "center",
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    transition:
      "box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "5px 8px 11px rgba(0, 0, 0, 0.1)",
      transform: "translateY(-5px)", // Move the box 5px up when hovered
      backgroundColor: theme.palette.background.alt,
    },
  };

  const iconStyle = {
    fontSize: "48px",
    color: theme.palette.grey[900],
    marginBottom: "8px",
  };
  const titleStyle = {
    color: theme.palette.grey[600],
    fontWeight: "bold",
  };
  const chartSetting = {
    xAxis: [
      {
        label: "times",
      },
    ],
    height: 400,
  };
  const valueFormatter = (value) => `${value} times`;
  return (
    <React.Fragment>
      <Box m="1.5rem 1.5rem">
        <Grid container spacing={2} sx={containerStyle} mt={1}>
          {dashboardData && (
            <React.Fragment>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={boxStyle}>
                  <Typography
                    variant="h6"
                    sx={titleStyle}
                    gutterBottom
                    align="left"
                  >
                    Total Items
                  </Typography>
                  <Box
                    alignItems="center"
                    display={"flex"}
                    justifyContent="space-between"
                  >
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                      {dashboardData.totalItems}
                    </Typography>

                    <ShoppingCartOutlined style={iconStyle} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={boxStyle}>
                  <Typography
                    variant="h6"
                    sx={titleStyle}
                    gutterBottom
                    align="left"
                  >
                    Total Categories
                  </Typography>
                  <Box
                    alignItems="center"
                    display={"flex"}
                    justifyContent="space-between"
                  >
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                      {dashboardData.totalCategories}
                    </Typography>

                    <CategoryOutlined style={iconStyle} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={boxStyle}>
                  <Typography
                    variant="h6"
                    sx={titleStyle}
                    gutterBottom
                    align="left"
                  >
                    Total Amount
                  </Typography>
                  <Box
                    alignItems="center"
                    display={"flex"}
                    justifyContent="space-between"
                  >
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                      ${dashboardData.ordersAmount}
                    </Typography>

                    <MonetizationOnOutlined style={iconStyle} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={boxStyle}>
                  <Typography
                    variant="h6"
                    sx={titleStyle}
                    gutterBottom
                    align="left"
                  >
                    Total Orders
                  </Typography>
                  <Box
                    alignItems="center"
                    display={"flex"}
                    justifyContent="space-between"
                  >
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                      {dashboardData.totalOrders}
                    </Typography>

                    <ReceiptLongOutlined style={iconStyle} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={boxStyle}>
                  <Typography
                    variant="h6"
                    sx={titleStyle}
                    gutterBottom
                    align="left"
                  >
                    Total Feedbacks
                  </Typography>
                  <Box
                    alignItems="center"
                    display={"flex"}
                    justifyContent="space-between"
                  >
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                      {dashboardData.totalFeedbacks}
                    </Typography>

                    <FeedbackOutlined style={iconStyle} />
                  </Box>
                </Box>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid container spacing={2} sx={containerStyle} mt={1}>
          <Grid item xs={12} md={6}>
            <Box sx={boxStyle}>
              <Typography
                variant="h6"
                sx={titleStyle}
                gutterBottom
                align="left"
              >
                Most Liked Items
              </Typography>
              {pieChartData && pieChartData?.length > 0 ? (
                <Box style={{ width: "100%" }}>
                  <PieChart
                    series={[
                      {
                        data: pieChartData, // Pass your pie chart data here
                        innerRadius: 5,
                        outerRadius: 120,
                        paddingAngle: 1,
                        arcLabelRadius: "50%",
                        cx: 120,
                        // cy: 370,
                      },
                    ]}
                    height={400}
                  />
                </Box>
              ) : (
                <CircularProgress  size={30}  sx={{color:"black"}} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={boxStyle}>
              <Typography
                variant="h6"
                sx={titleStyle}
                gutterBottom
                align="left"
              >
                Most Ordered Items
              </Typography>
              {barChartData && barChartData?.length > 0 ? (
                <BarChart
                  dataset={barChartData}
                  yAxis={[{ scaleType: "band", dataKey: "label" }]}
                  series={[
                    {
                      dataKey: "value",
                      label: "Ordered Times",
                      valueFormatter,
                    },
                  ]}
                  layout="horizontal"
                  {...chartSetting}
                  margin={{ left: 130 }} // Adjust margins as needed
                  barWidth={10}
                />
              ) : (
                <CircularProgress  size={30}  sx={{color:"black"}} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={boxStyle}>
              <Typography
                variant="h6"
                sx={titleStyle}
                gutterBottom
                align="left"
              >
                Orders Ten Days Ago
              </Typography>
              {barChartData1 && barChartData1?.length > 0 ? (
                <BarChart
                  height={600}
                  series={[
                    {
                      data:
                        barChartData1 &&
                        barChartData1?.map((item) => item.totalOrders),
                      label: "count",
                      id: "valueId",
                      yAxisKey: "leftAxisId",
                    },
                    {
                      data:
                        barChartData1 &&
                        barChartData1?.map((item) => item.totalAmount),
                      label: "Total Price",
                      id: "value1Id",
                      yAxisKey: "rightAxisId",
                    },
                  ]}
                  xAxis={[
                    {
                      data:
                        barChartData1 && barChartData1?.map((item) => item._id),
                      scaleType: "band",
                    },
                  ]}
                  yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
                  rightAxis="rightAxisId"
                />
              ) : (
                <CircularProgress  size={30}  sx={{color:"black"}} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
