import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../utils/config";

export const fetchOrdersAction = createAsyncThunk(
  "orders/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/orders`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchOrderAction = createAsyncThunk(
  "orders/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/orders/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteOrdersAction = createAsyncThunk(
  "orders/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/orders/${id}`, config);
      dispatch(fetchOrdersAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

const UPDATE_ROW_ORDER = "orders/updateRowOrder";

// Define the action creator function for updating row order
export const updateRowOrderAction = createAsyncThunk(
  UPDATE_ROW_ORDER,
  async (newRowOrder, { rejectWithValue, dispatch }) => {
    try {
      // Make an API call to update the row order on the server
      const response = await axios.put(`${baseUrl}/orders/`, newRowOrder);

      // Return the updated row order data if successful
      dispatch(fetchOrdersAction());
      return response.data;
    } catch (error) {
      // Handle error if the API call fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const ordersSlices = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //all details
    builder.addCase(fetchOrdersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchOrdersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchOrdersAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteOrdersAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrdersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update  state after deletion if needed
    });
    builder.addCase(deleteOrdersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    // details
    builder.addCase(fetchOrderAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchOrderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchOrderAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);

    builder.addCase(updateRowOrderAction.fulfilled, (state, action) => {
      // Update the row order in the state with the data returned from the server
      state.rowOrder = action.payload;
    });
  },
});

export default ordersSlices.reducer;
