import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  DialogActions,
  useTheme,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import Header from "../../component/Header";
import {
  updateCategoryAction,
  fetchCategoryAction,
  resetState,
} from "../../state/categories/categorySlice";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const EditCategory = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("No file chosen");

  const isSuccess = useSelector((state) => state.categories.isSuccess);
  const appErr = useSelector((state) => state.categories.appErr);
  const loading = useSelector((state) => state.categories.loading);
  const category = useSelector((state) => state.categories.category);
  const state = useSelector((state) => state?.categories);
  const { catUpdated } = state;

  useEffect(() => {
    if (id) {
      dispatch(fetchCategoryAction(id));
    }
  }, [dispatch, id, isSuccess]);


  useEffect(() => {
    if (isSuccess && catUpdated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, catUpdated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);
 
  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    main_image: Yup.mixed().required("Image is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name || "", // Ensure that the initial value is not undefined
      main_image: category?.main_image || null, // Set initial value appropriately
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        name: values?.name,
        //  main_image: values?.main_image instanceof File ? values?.main_image : values?.main_image
        main_image: values?.main_image,
      };
      dispatch(updateCategoryAction({ data, id }));
      handleClose();
      setFileName("No file chosen");
      console.log(values);
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "35%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="Categories" subtitle="Add Categories" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Allergen Name :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Name: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Category Image :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Image: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      formik.setFieldValue("main_image", acceptedFiles[0]);
                      setFileName(acceptedFiles[0].name);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept="image/*" />
                        <Button
                          variant="contained"
                          startIcon={<Upload />}
                          color="secondary"
                          style={{ marginTop: "18px", marginBottom: "10px" }}
                        >
                          Choose Image
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                  <div style={{ color: "#d32f2f" }}>
                    {formik.touched.main_image &&
                      Boolean(formik.errors.main_image)}
                    {formik.touched.main_image && formik.errors.main_image}
                  </div>
                  <span>{fileName}</span>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditCategory;
