import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Button,
  Tooltip,
} from "@mui/material";
import { Rating } from "@mui/material";
import Header from "../../component/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchFeedbacksAction,
  deleteFeedbacksAction,
  updateRowOrderAction,
} from "../../state/feedbacks/feedbackSlice";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";

const Feedbacks = () => {
  const columns = [
    {
      accessorKey: "orderNumber",
      header: "ID",
      size: "100",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "comment",
      header: "Comment",
      Cell: ({ row }) => (
        <Tooltip placement="top-end" title={row.original.comment}>
          <span> {row.original.comment}</span>
        </Tooltip>
      ),
    },
    {
      accessorKey: "dishesRate",
      header: "Dishes Rate",
      Cell: ({ row }) => (
        <Rating name="dishes-rate" value={row.original.dishesRate} readOnly />
      ),
    },
    {
      accessorKey: "serviceRate",
      header: "Service Rate",
      Cell: ({ row }) => (
        <Rating name="service-rate" value={row.original.serviceRate} readOnly />
      ),
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      header: "Actions",
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState(null);
  const dispatch = useDispatch();
  const feedbacks = useSelector((state) => state.feedbacks.Details);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  useEffect(() => {
    dispatch(fetchFeedbacksAction());
  }, [dispatch]);

  useEffect(() => {
    if (feedbacks) {
      const data = feedbacks.map((item) => ({ ...item, key: item.id }));
      setDataSource(data);
    }
  }, [feedbacks]);

  const handleDeleteFeedback = async () => {
    if (feedbackToDelete) {
      await dispatch(deleteFeedbacksAction(feedbackToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchFeedbacksAction());
      setToastMessage("Feedback deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (feedbackId) => {
    setFeedbackToDelete(feedbackId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setFeedbackToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleRowOrderChange = (newData) => {
    // Dispatch the action to update the row order
    dispatch(updateRowOrderAction(newData))
      .then(() => {
        // If the action was successful, update the local state
        setDataSource(newData);
        console.log(newData);
        dispatch(fetchFeedbacksAction());
      })
      .catch((error) => {
        console.error("Error updating row order:", error);
        // Handle the error if necessary
        // You might want to show a toast message or handle the error in some other way
      });
  };
  const table = useMaterialReactTable({
    columns,
    data: dataSource,
    enableColumnVirtualization: true,
    enableColumnPinning: true,
    //  enableRowNumbers: true,
    enableRowOrdering: true,
    enableSorting: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });

  return (
    <>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this feedback?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteFeedback} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={"bottom-left"}
      />
      <Box m="1rem 1.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Feedbacks" subtitle="List of Feedbacks" />
        </div>
        <Box mt="10px" height="100vh">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Feedbacks;
