import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
//import Header from "../../component/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItemsAction,
  updateItemOrderAction,
} from "../../state/items/itemsSlice";

import {
  fetchCategoriesAction,
  updateRowOrderAction,
} from "../../state/categories/categorySlice";
import { fetchDetailsAction } from "../../state/extras/extrasSlice";
import { fetchDataAction } from "../../state/ingredientsExtras/ingredientsExtrasSlice";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const Sorting = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchItemsAction());
    dispatch(fetchCategoriesAction());
    dispatch(fetchDetailsAction());
    dispatch(fetchDataAction());
  }, [dispatch]);

  const items = useSelector((state) => state.items.Details?.items);
  const categories = useSelector((state) => state.categories.catDetails);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (items) {
      const data = items.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [items]);

  // const updatedDataSource = dataSource.map((item) => {
  //   const categoryName = categoryLookup[item.catId];

  //   return {
  //     ...item,
  //     catId: categoryName !== undefined ? categoryName : "Unknown Category",
  //   };
  // });

  useEffect(() => {
    if (items && categories) {
      const categoryLookup = {};
      categories &&
        categories.forEach((category) => {
          categoryLookup[category._id] = category.name;
        });
      const updatedData = items.map((item) => {
        const categoryName = categoryLookup[item.catId] || "Unknown Category";
        return {
          ...item,
          catId: categoryName,
        };
      });
      setDataSource(updatedData);
    }
  }, [items, categories]);

  const [draggedItem, setDraggedItem] = useState(null); // State to track the currently dragged item
  const [category, setCategories] = useState([]);

  // Event handler for when a category box starts being dragged
  const handleDragStart = (event, category) => {
    setDraggedItem(category); // Set the dragged item in state
  };

  // In your component:
  const [isHovered, setIsHovered] = useState(false);

  // Event handler for when a category box is dragged over
  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior
    setIsHovered(true); // Set hover state to true
  };

  // Event handler for when the drag operation finishes
  const handleDragEnd = () => {
    setIsHovered(false); // Reset hover state when drag ends
  };

  // Calculate the style based on the hovered state
  const getBoxStyle = () => {
    if (isHovered) {
      return {
        transform: "translateY(-10px)", // Move the box upward slightly
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Add a shadow effect
      };
    }
    return {}; // Return default style if not hovered
  };
  const handleDrop = (event, targetCategory) => {
    event.preventDefault(); // Prevent default behavior

    // Find the index of the target category
    const targetIndex = categories.findIndex(
      (category) => category.id === targetCategory.id
    );

    // Find the index of the dragged item
    const draggedIndex = categories.findIndex(
      (category) => category.id === draggedItem.id
    );

    // Create a copy of the categories array to avoid mutating state directly
    const updatedCategories = [...categories];

    // Remove the dragged item from its current position
    const draggedCategory = updatedCategories.splice(draggedIndex, 1)[0];

    // Insert the dragged item at the target position
    updatedCategories.splice(targetIndex, 0, draggedCategory);

    // Update state with the new order of categories
    setCategories(updatedCategories);

    // After handling the drop, clear the dragged item from state
    setDraggedItem(null);
  };

  useEffect(() => {
    // This effect will run after the state has been updated
    console.log(category); // Ensure that categories reflect the updated state
    dispatch(updateRowOrderAction(category));
  }, [category, dispatch]); // Run the effect whenever categories or dispatch changes

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        //  style={{ overflowY: "auto", maxHeight: "80vh" }} // Add these styles
      >
        {categories &&
          categories.map((category) => {
            const categoryItems = dataSource.filter(
              (item) => item.catId === category.name
            );

            // Check if category has items, only render if it does
            if (categoryItems.length > 0) {
              return (
                <Box
                  width="100%"
                  key={category._id}
                  onDragStart={(event) => handleDragStart(event, category)}
                  onDragOver={handleDragOver}
                  onDragEnd={handleDragEnd}
                  onDrop={(event) => handleDrop(event, category)}
                  draggable // Enable draggable attribute
                  style={{ cursor: "move", ...getBoxStyle() }} // Apply dynamic styles
                  mt={4}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    gutterBottom
                    marginLeft={6}
                    fontWeight="bold"
                    mt={2}
                  >
                    {category.name}
                  </Typography>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))"
                    gap="1rem"
                    padding="0 2.5rem"
                  >
                    {categoryItems.map((item) => (
                      <Box key={item.id}>
                        <Card sx={{ maxWidth: 280 }}>
                          <CardHeader title={item.name} />
                          <CardMedia
                            component="img"
                            height="110"
                            image={item.main_image}
                            alt={item.name}
                            style={{ objectFit: "contain" }}
                          />
                          <CardContent>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.secondary"
                              style={{ marginTop: "0.2rem" }}
                            >
                              Initial Price:
                              <span
                                style={{
                                  marginLeft: "1rem",
                                  float: "right",
                                  color: "red",
                                }}
                              >
                                ${item && item.initialPrice}
                              </span>
                            </Typography>

                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.secondary"
                              style={{ marginTop: "0.2rem" }}
                            >
                              Offer Price:
                              <span
                                style={{
                                  marginLeft: "1rem",
                                  float: "right",
                                  color: "red",
                                }}
                              >
                                $
                                {item && item.offerPrice !== null
                                  ? item.offerPrice
                                  : "0"}
                              </span>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            } else {
              return null; // Return null if category has no items
            }
          })}
      </Box>
    </>
  );
};

export default Sorting;
