import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  CalendarMonthOutlined,
  FeedbackOutlined,
  CategoryOutlined,
} from "@mui/icons-material";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
    label: "dashboard",
  },
  {
    text: "Client Facing",
    icon: null,
  },
  {
    text: "Sliders",
    icon: <SlideshowIcon />,
    label: "dashboard/sliders",
  },
  {
    text: "Items",
    icon: <ShoppingCartOutlined />,
    label: "dashboard/items",
  },
  {
    text: "Categories",
    icon: <CategoryOutlined />,
    label: "dashboard/categories",
  },

  {
    text: "Item Extras",
    icon: <Groups2Outlined />,
    label: "dashboard/extras",
  },
  {
    text: " Free Extras",
    icon: <Groups2Outlined />,
    label: "dashboard/ingredientsExtras",
  },
  {
    text: "Allergens",
    icon: <Groups2Outlined />,
    label: "dashboard/allergens",
  },
  {
    text: "Sales",
    icon: null,
  },
  {
    text: "Orders",
    icon: <ReceiptLongOutlined />,
    label: "dashboard/orders",
  },
  {
    text: "PromoCode",
    icon: <CalendarMonthOutlined />,
    label: "dashboard/promoCode",
  },
  {
    text: "Feedbacks",
    icon: <FeedbackOutlined />,
    label: "dashboard/feedbacks",
  },

  // {
  //   text: "Management",
  //   icon: <AdminPanelSettingsOutlined />,

  //   children: [
  //     {
  //       text: " Contacts",
  //       icon: <ShoppingCartOutlined />,
  //       label: "dashboard/contacts",
  //     },

  //   ],
  // },

  {
    text: "General Settings",
  },

  {
    text: "Settings",
    icon: <SettingsOutlined />,
    label: "dashboard/settings",
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const [openCategoryIndex, setOpenCategoryIndex] = useState(null);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleCategoryClick = (index) => {
    setOpenCategoryIndex(index === openCategoryIndex ? null : index);
  };
  useEffect(() => {
    if (!isNonMobile) {
      setIsSidebarOpen(false);
    }
  }, [isNonMobile, setIsSidebarOpen]);
  useEffect(() => {
    if (isNonMobile) {
      setIsSidebarOpen(true);
    }
  }, [isNonMobile, setIsSidebarOpen]);
  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.grey[400],
              backgroundColor: "#2e3230",
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    ECOMVISION
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft
                      sx={{
                        ml: "1rem",
                        color: theme.palette.grey[300],
                      }}
                    />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <Divider />
            <List>
              {navItems.map(({ text, icon, label, children }, index) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 1.5rem" }}>
                      {text}
                    </Typography>
                  );
                }
                //  const lcText = text.toLowerCase();

                return (
                  <React.Fragment key={text}>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (children) {
                            handleCategoryClick(index);
                          } else {
                            navigate(`/${label}`);
                            setActive(label);
                          }
                        }}
                        sx={{
                          backgroundColor:
                            active === label
                              ? theme.palette.secondary[500]
                              : "transparent",
                          color:
                            active === label
                              ? theme.palette.grey[900]
                              : theme.palette.grey[300],

                          "&:hover": {
                            color:
                              active === label
                                ? theme.palette.grey[500]
                                : theme.palette.grey[100], // Change the color to white or any desired color
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "1rem",
                            color:
                              active === label
                                ? theme.palette.grey[900]
                                : theme.palette.grey[300],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {children && (
                          <ChevronRightOutlined
                            sx={{
                              transform:
                                openCategoryIndex === index
                                  ? "rotate(90deg)"
                                  : "rotate(0)",
                              transition: "transform 0.3s ease",
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                    {children && openCategoryIndex === index && (
                      <List disablePadding>
                        {children.map(
                          (
                            {
                              text: childText,
                              icon: childIcon,
                              label: childLabel,
                            },
                            childIndex
                          ) => (
                            <ListItem key={childText} disablePadding>
                              <ListItemButton
                                onClick={() => {
                                  navigate(`/${childLabel}`);
                                  setActive(childLabel);
                                }}
                                sx={{
                                  backgroundColor:
                                    active === childLabel
                                      ? theme.palette.secondary[300]
                                      : "transparent",
                                  color:
                                    active === childLabel
                                      ? theme.palette.primary[600]
                                      : theme.palette.secondary[100],
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    ml: "4rem",
                                    color:
                                      active === childLabel
                                        ? theme.palette.primary[600]
                                        : theme.palette.secondary[200],
                                  }}
                                >
                                  {childIcon}
                                </ListItemIcon>
                                <ListItemText primary={childText} />
                              </ListItemButton>
                            </ListItem>
                          )
                        )}
                      </List>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};
export default Sidebar;
