import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Drawer,
  Typography,
  Grid,
  Button,
  DialogActions,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  updateDetailsAction,
  fetchDetailAction,
  resetState,
} from "../../state/unit/unit";

import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const EditUnit = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSuccess = useSelector((state) => state?.units.isSuccess);
  const appErr = useSelector((state) => state?.units.appErr);
  const Updated = useSelector((state) => state?.units.Updated);
  const loading = useSelector((state) => state?.units.loading);
  const data = useSelector((state) => state?.units.data);

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailAction(id));
    }
  }, [dispatch, id, isSuccess]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    unit: Yup.string().required("Unit is required"),
    deliveryCost: Yup.number(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      unit: data?.unit || "",

      deliveryCost: data?.deliveryCost || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        unit: values?.unit,
        deliveryCost: values?.deliveryCost,
      };
      dispatch(updateDetailsAction({ data, id }));
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  // if (isEdited) return <Navigate to="/dashboard/aboutUs" />;

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }}
        PaperProps={{
          sx: {
            width: "45%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb="1rem">
              <Header title="Pricing Details" subtitle="Edit Details" />
            </Box>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Unit :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Unit Name: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="unit"
                    name="unit"
                    label="Unit"
                    variant="outlined"
                    fullWidth
                    value={formik.values.unit}
                    onChange={formik.handleChange}
                    error={formik.touched.unit && Boolean(formik.errors.unit)}
                    helperText={formik.touched.unit && formik.errors.unit}
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Delivery Cost :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Cost:
                  </Typography>
                  <TextField
                    id="deliveryCost"
                    name="deliveryCost"
                    label="Delivery Cost"
                    variant="outlined"
                    fullWidth
                    value={formik.values.deliveryCost}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.deliveryCost &&
                      Boolean(formik.errors.deliveryCost)
                    }
                    helperText={
                      formik.touched.deliveryCost && formik.errors.deliveryCost
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                size="small"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={loading}
                size="small"
              >
                {!loading ? "Submit" : <Loader />}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditUnit;
