import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,Tooltip
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useMaterialReactTable,MaterialReactTable} from 'material-react-table';
import Header from "../../component/Header";
import {
  fetchDetailsAction,
  deleteDetailsAction,
} from "../../state/navItems/navItems";
import AddNavForm from "./addNav";
import EditNavItems from "./editNav";

const NavItems = () => {

  const dispatch = useDispatch();


  const [dataSource, setDataSource] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);

  const nav = useSelector((state) => state.navItems.Details);

  useEffect(() => {
    dispatch(fetchDetailsAction());
  }, [dispatch]);

  
  useEffect(() => {
    if (nav) {
      const data = nav.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [nav]);
  const openHandler = () => {
    setOpenDrawer((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };

  const handleDeletePromoCode = async () => {
    if (dataToDelete) {
      await dispatch(deleteDetailsAction(dataToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchDetailsAction());
      setToastMessage("Item deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (promoCodeId) => {
    setDataToDelete(promoCodeId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDataToDelete(null);
    setDeleteConfirmationOpen(false);
  };
  const handleRowOrderChange = (newData) => {
    // Update the row order directly in the Redux store
   // dispatch(updateRowOrderAction(newData));
    setDataSource(newData); // Optionally, you can also update local state if needed
  };

 
  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "itemToDisplay",
      header: "Item To Display",
      size: 100,
    },
    {
      accessorKey: "link",
      header: "Link",
      size: 150,
    },
   
    {
      header: "Actions",
      Cell: ({ row }) => (
        <span>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            onClick={() => handleConfirmDelete(row.original._id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            color="error"
            onClick={() => handleEditDetails(row.original._id)}
          >
          <EditIcon />
          </IconButton>
        </Tooltip>
        </span>
        
      ),
    },
  ];
  const handleEditDetails = (id) => {
    openEditHandler(true);
    setEditId(id);
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data:dataSource,
    enableRowOrdering: true,
    enableSorting: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });
  return (
    <>
      <EditNavItems
        handleClose={() => setOpenEditDrawer(false)}
        open={openEditDrawer}
        id={editId}
      />

      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeletePromoCode} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={"bottom-left"}
      />

      <Box m="1.5rem 2.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Nav Items" subtitle="List of Nav Items" />
          <Button
            variant="contained"
            onClick={openHandler}
            color="secondary"
            size="lg"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
          <AddNavForm
            handleClose={() => setOpenDrawer(false)}
            open={openDrawer}
          />
        </div>

        <Box mt="40px" height="75vh">
  
        <MaterialReactTable table={table} />

      </Box>
      </Box>


    </>
  );
};

export default NavItems;