import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";
import Header from "../../component/Header";
import { addTermsAction, resetState } from "../../state/terms/terms"; // Update import
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
const AddTerms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.terms.isSuccess);
  const appErr = useSelector((state) => state.terms.appErr);
  const added = useSelector((state) => state.terms.added);
  const loading = useSelector((state) => state.terms.loading);

  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Details Added Successfully!");
      setTimeout(() => {
        navigate("/dashboard/terms");
      }, 4000);
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, added, navigate]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      text: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addTermsAction(values));
      console.log(values);
      formik.resetForm();
      setSubmitting(false);

      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  const handleBack = () => {
    navigate(`/dashboard/terms/`);

  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box m="1.5rem 2.5rem">
      <Button
        style={{
          display: "flex",
          alignItems: "center",

          marginBottom: "2rem",
        }}
        color="primary"
        variant="contained"
        onClick={handleBack}
        size="lg"
        //   startIcon={<AddCircleOutlineIcon />}
      >
        Back
      </Button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "30px",
          }}
        >
          <Header
            title="Terms of Use Details"
            subtitle="Add Terms of Use"
          />
        </div>
        <Box
          width="100%"
          mb="1.5rem"
          sx={{
            "& .ql-editor": {
              height: "200px", // Set the height of the editor
              border: "1px solid #ccc", // Example border
              borderRadius: "4px", // Example border radius
              marginTop: "40px",
            },
          }}
        >
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            name="text"
            value={formik.values.text}
            onChange={(value) => formik.setFieldValue("text", value)}
            error={formik.touched.text && Boolean(formik.errors.text)}
          />
          {formik.touched.text && formik.errors.text && (
            <div style={{ color: "red" }}>{formik.errors.text}</div>
          )}
        </Box>

        {loading ? (
          <Button type="submit" variant="contained" color="secondary">
            Loading please wait...
          </Button>
        ) : (
          <Button type="submit" variant="contained" color="secondary">
            Submit
          </Button>
        )}
      </Box>
    </form>
  );
};

export default AddTerms;