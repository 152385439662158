import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Tooltip,
  FormControl,
  useTheme,
  MenuItem,
  Select,
} from "@mui/material";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../../component/Header";
import {
  fetchDataAction,
  deleteDetailsAction,
  updateRowOrderAction,
} from "../../state/translation/translationSlice";

import EditExtras from "./editForm";
import AddForm from "./addForm";

const Translation = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [dataSource, setDataSource] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [extrasToDelete, setExtrasToDelete] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);
  const [selectedShortcut, setSelectedShortcut] = useState("All");
  const translation = useSelector((state) => state?.translation?.Details);

  useEffect(() => {
    dispatch(fetchDataAction());
  }, [dispatch]);

  useEffect(() => {
    if (translation) {
      const data =
        translation && translation?.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [translation]);

  const openHandler = () => {
    setOpenDrawer((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };

  const handleDeleteExtras = async () => {
    if (extrasToDelete) {
      await dispatch(deleteDetailsAction(extrasToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchDataAction());
      setToastMessage("Extras deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (id) => {
    setExtrasToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setExtrasToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleRowOrderChange = (newData) => {
    // Dispatch the action to update the row order
    dispatch(updateRowOrderAction(newData))
      .then(() => {
        // If the action was successful, update the local state
        setDataSource(newData);
        dispatch(fetchDataAction());
      })
      .catch((error) => {
        console.error("Error updating row order:", error);
        // Handle the error if necessary
        // You might want to show a toast message or handle the error in some other way
      });
  };
  const columns = [
    {
      accessorKey: "orderNumber",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "label",
      header: "Label",
      size: 100,
    },

    {
      accessorKey: "translatedWord",
      header: "Translated Word",
      size: 100,
    },
    {
      header: "Actions",
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="error"
              onClick={() => handleEditDetails(row.original._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const handleEditDetails = (id) => {
    //navigate(`/dashboard/editAboutUs/${Id}`)
    openEditHandler();
    setEditId(id);
  };

  // Filter data based on the selected shortcut
  useEffect(() => {
    if (!translation) {
      return;
    }

    if (selectedShortcut === "All") {
      setDataSource(translation);
    } else {
      const filteredData = translation.filter(
        (item) => item?.shortcut === selectedShortcut
      );
      setDataSource(filteredData);
    }
  }, [selectedShortcut, translation]);

  // Handler for select change
  const handleShortcutChange = (event) => {
    setSelectedShortcut(event?.target?.value);
  };
  const [uniqueShortcuts, setUniqueShortcuts] = useState([]);
  useEffect(() => {
    if (!translation) {
      return;
    }
  
    const shortcuts = Array.from(new Set(translation.map((item) => item.shortcut)));
    setUniqueShortcuts(shortcuts);
  }, [translation]);
  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: dataSource,
    enableRowOrdering: true,
    enableSorting: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });

  const handleToastClose = () => {
    setOpenToast(false);
  };

  return (
    <>
      <AddForm handleClose={openHandler} open={openDrawer} />
      <EditExtras
        handleClose={openEditHandler}
        open={openEditDrawer}
        id={editId}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Data?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteExtras} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={"bottom-left"}
      />

      <Box m="1rem 1.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Translation List" subtitle="List " />
          <Button
            variant="contained"
            onClick={openHandler}
            color="secondary"
            size="lg"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
        </div>

        <Box mt="10px" height="75vh" bgcolor={theme.palette.background.default}>
          {/* <InputLabel id="demo-simple-select-label">Select Language</InputLabel>*/}
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              value={selectedShortcut}
              onChange={handleShortcutChange}
              displayEmpty
              inputProps={{ "aria-label": "Select shortcut" }}
              style={{
                backgroundColor: theme.palette.background.alt,
                minWidth: 120,
                margin: "6px",
              }} // Set background color of the Select button
            >
              <MenuItem value="All">All Languages</MenuItem>
              {uniqueShortcuts &&
                uniqueShortcuts.map((shortcut) => (
                  <MenuItem
                    key={shortcut}
                    value={shortcut}
                    style={{ backgroundColor: "white" }}
                  >
                    {shortcut}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Translation;
