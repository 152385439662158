import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Typography,
  Grid,
  Drawer,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  useTheme,
  DialogActions,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import Header from "../../component/Header";
import {
  updateContactsAction,
  fetchContactAction,
  resetState,
} from "../../state/contacts/contactsSlice";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Loader from "../../component/loader";

const EditContact = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("No file chosen");

  const contact = useSelector((state) => state.contacts.contact);
  const isSuccess = useSelector((state) => state.contacts.isSuccess);
  const appErr = useSelector((state) => state.contacts.appErr);
  const Updated = useSelector((state) => state?.contacts.Updated);
  const loading = useSelector((state) => state?.contacts.loading);
  useEffect(() => {
    if (id) {
      dispatch(fetchContactAction(id));
    }
  }, [dispatch, id, isSuccess]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
      
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);
 
  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    logo: Yup.mixed().required("Logo is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.string().required("Phone number is required"),
    languages: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Language name is required"),
        shortcut: Yup.string().required("Shortcut is required"),
        default_lang: Yup.boolean(),
      })
    ),

    socials: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Social name is required"),
        link: Yup.string().required("Link is required"),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: contact?.name || "",
      logo: contact?.logo || null,
      address: contact?.address || "",
      phone: contact?.phone || "",
      languages: contact?.languages || [
        { name: "", shortcut: "", default_lang: "false" },
      ],
      socials: contact?.socials || [{ name: "", link: "" }],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        name: values?.name,
        logo: values?.logo,
        address: values?.address,
        phone: values?.phone,
        languages: values?.languages,
        socials: values?.socials,
      };
      dispatch(updateContactsAction({ data, id }));
      console.log(values);
      setFileName("No file chosen");
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  const addLanguageField = () => {
    formik.setFieldValue("languages", [
      ...formik.values.languages,
      { name: "", shortcut: "" },
    ]);
  };
  const removeLanguagesField = (indexToRemove) => {
    // Check if there is more than one ingredient
    if (formik.values.languages && formik.values.languages.length > 1) {
      const updatedLanguages = formik.values.languages.filter(
        (_, index) => index !== indexToRemove
      );
      formik.setFieldValue("languages", updatedLanguages);
    }
  };

  const addSocialField = () => {
    formik.setFieldValue("socials", [
      ...formik.values.socials,
      { name: "", link: "", default_lang: "" },
    ]);
  };
  const removeSocialField = (indexToRemove) => {
    // Check if there is more than one ingredient
    if (formik.values.socials && formik.values.socials.length > 1) {
      const updatedSocials = formik.values.socials.filter(
        (_, index) => index !== indexToRemove
      );
      formik.setFieldValue("socials", updatedSocials);
    }
  };

  return (
    <React.Fragment>
      <Drawer
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999" }}
        anchor="right"
        PaperProps={{ sx: { width: "75%" } }}
      >
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Box m="1.5rem 2.5rem">
            <Header title="Contact" subtitle="Add Contact Details" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  {/* Name Box */}
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={4}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Company Name :
                    </Typography>
                    <hr
                      style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Name: <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {/* Address Box */}
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={4}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Company Address :
                    </Typography>
                    <hr
                      style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Address: <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      variant="outlined"
                      fullWidth
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      //style={{ marginTop: "25px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {/* Phone Box */}
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={4}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Company Phone :
                    </Typography>
                    <hr
                      style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Phone Number: <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="phone"
                      name="phone"
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                      //  style={{ marginTop: "25px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {/* Logo Box */}
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={4}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Company Logo :
                    </Typography>
                    <hr
                      style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Logo: <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        formik.setFieldValue("logo", acceptedFiles[0]);
                        setFileName(acceptedFiles[0].name);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} accept="image/*" />

                          <Button
                            variant="contained"
                            startIcon={<Upload />}
                            color="secondary"
                            style={{ marginTop: "25px" }}
                          >
                            Choose Logo
                          </Button>
                        </div>
                      )}
                    </Dropzone>

                    <div style={{ color: "#d32f2f" }}>
                      {formik.touched.logo && Boolean(formik.errors.logo)}
                      {formik.touched.logo && formik.errors.logo}
                    </div>
                    <span>{fileName}</span>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  {/* Languages Section */}
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={4}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Provided Languages :
                    </Typography>
                    <hr
                      style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                    />

                    {formik.values.languages.map((language, index) => (
                      <div key={index}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h6" gutterBottom>
                            Languages: <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <IconButton
                            onClick={() => removeLanguagesField(index)}
                            color="secondary"
                            size="medium"
                            disabled={formik.values.languages.length === 1}
                          >
                            <Close fontSize="medium" />
                          </IconButton>
                        </Box>

                        <Box key={index} style={{ marginTop: "15px" }}>
                          <TextField
                            name={`languages[${index}].name`}
                            label="Language Name"
                            variant="outlined"
                            fullWidth
                            value={language.name}
                            onChange={formik.handleChange}
                          />

                          <TextField
                            id={`Name-${index}`}
                            name={`languages[${index}].shortcut`}
                            label="Shortcut"
                            variant="outlined"
                            fullWidth
                            value={language.shortcut}
                            onChange={formik.handleChange}
                            style={{ marginTop: "10px" }}
                          />
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Default Language:
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label={`default-lang-${index}`}
                              name={`languages[${index}].default_lang`}
                              value={
                                language.default_lang &&
                                language.default_lang.toString()
                              } // Convert boolean to string for comparison
                              onChange={(event) => {
                                const { value } = event.target;
                                // Convert string to boolean and set the value
                                formik.setFieldValue(
                                  `languages[${index}].default_lang`,
                                  value === "true" ? true : false
                                );
                              }}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="True"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="False"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </div>
                    ))}
                    <Button
                      onClick={addLanguageField}
                      color="secondary"
                      style={{ marginTop: "15px" }}
                    >
                      Add Language
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {/* Socials Section */}
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={4}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Company Social Media :
                    </Typography>
                    <hr
                      style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                    />

                    {formik.values.socials.map((social, index) => (
                      <div key={index}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h6" gutterBottom>
                            Socials: <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <IconButton
                            onClick={() => removeSocialField(index)}
                            color="secondary"
                            size="medium"
                            disabled={formik.values.socials.length === 1}
                          >
                            <Close fontSize="medium" />
                          </IconButton>
                        </Box>
                        <Box key={index} style={{ marginTop: "15px" }}>
                          <TextField
                            name={`socials[${index}].name`}
                            label="Social Name"
                            variant="outlined"
                            fullWidth
                            value={social.name}
                            onChange={formik.handleChange}
                          />
                          <TextField
                            name={`socials[${index}].link`}
                            label="Link"
                            variant="outlined"
                            fullWidth
                            value={social.link}
                            onChange={formik.handleChange}
                            style={{ marginTop: "10px" }}
                          />
                        </Box>
                      </div>
                    ))}
                    <Button
                      onClick={addSocialField}
                      color="secondary"
                      style={{ marginTop: "15px" }}
                    >
                      Add Social
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <DialogActions style={{ marginTop: "18px" }}>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditContact;
