import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./auth/authSlices";
import categoriesReducer from "./categories/categorySlice";
import allergensReducer from "./allergens/allergensSlice";
import feedbackReducer from "./feedbacks/feedbackSlice";
import contactsReducer from "./contacts/contactsSlice";
import workingHoursReducer from "./workingHours/workingSlice";
import aboutUsReducer from "./aboutus/aboutUsSlice";
import promoCodeReducer from "./promoCode/promoCodeSlice";
import privacyReducer from "./privacy/privacy";
import termsReducer from "./terms/terms";
import mapsReducer from "./maps/mapsSlice";
import colorsReducer from "./colors/colorsSlice";
import slidersReducer from "./sliders/sliderSlice";
import ordersReducer from "./orders/ordersSlice";
import unitReducer from "./unit/unit";
import navItemsReducer from "./navItems/navItems";
import itemsReducer from "./items/itemsSlice";
import extrasReducer from "./extras/extrasSlice";
import ingredientsExtrasReducer from "./ingredientsExtras/ingredientsExtrasSlice";
import dashboardReducer from "./dashboard/dashboard";
import translationReducer from "./translation/translationSlice";
import tokenReducer from "./token/tokenSlice";
import { api } from "./api";
import globalReducer from "./index";
export const store = configureStore({
  reducer: {
    users: usersReducer,
    global: globalReducer,
    api: api.reducer,
    categories: categoriesReducer,
    allergens: allergensReducer,
    feedbacks: feedbackReducer,
    contacts: contactsReducer,
    workingHours: workingHoursReducer,
    aboutUs: aboutUsReducer,
    promoCode: promoCodeReducer,
    privacy: privacyReducer,
    terms: termsReducer,
    maps: mapsReducer,
    colors: colorsReducer,
    sliders: slidersReducer,
    orders: ordersReducer,
    units: unitReducer,
    navItems: navItemsReducer,
    items: itemsReducer,
    extras: extrasReducer,
    ingredientsExtras: ingredientsExtrasReducer,
    dashboard: dashboardReducer,
    translation: translationReducer,
    token: tokenReducer,
  },
  //middleware: (getDefault) => getDefault().concat(api.middleware),
});
