import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../component/Header";
import {
  Box,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Skeleton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  fetchSlidersAction,
  deleteSlidersAction,
} from "../../state/sliders/sliderSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import AddSlider from "./addSlider";
import EditSlider from "./editSlider";

const Sliders = () => {
  const dispatch = useDispatch();
  const [sliderDetails, setSliderDetails] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [sliderDetailToDelete, setSliderDetailToDelete] = useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    dispatch(fetchSlidersAction());
  }, [dispatch]);
  const isLoading = useSelector((state) => state.sliders.isLoading);
  const sliderData = useSelector((state) => state.sliders.Details);
  //console.log(state);
  useEffect(() => {
    if (sliderData) {
      setSliderDetails(sliderData);
    }
  }, [sliderData]);

  const openHandler = () => {
    setOpenDrawer((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };

  const handleDeleteSliderDetail = async () => {
    if (sliderDetailToDelete) {
      await dispatch(deleteSlidersAction(sliderDetailToDelete));
      setDeleteConfirmationOpen(false);
      setToastMessage("Slider detail deleted successfully");
      setOpenToast(true);
      dispatch(fetchSlidersAction());
    }
  };

  const handleConfirmDelete = (sliderDetail) => {
    setSliderDetailToDelete(sliderDetail);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setSliderDetailToDelete(null);
    setDeleteConfirmationOpen(false);
  };
  const handleEditDetails = (id) => {
    openEditHandler();
    setEditId(id);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  return (
    <>
      <AddSlider handleClose={openHandler} open={openDrawer} />
      <EditSlider
        handleClose={openEditHandler}
        open={openEditDrawer}
        id={editId}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this language?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSliderDetail} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
      <Box m="1rem 1.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Slider" subtitle="List of Sliders" />
          <Button
            className="add-slider-button"
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={openHandler}
          >
            Add Slider Detail
          </Button>
        </div>
      </Box>

      <Container fixed style={{ height: "100vh" }}>
        {!isLoading && (
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            navigation={true}
            //loop={true}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              //  paddingTop: '50px', // Adjust as needed for vertical centering
            }}
          >
            {sliderDetails.map((sliderDetail) => (
              <SwiperSlide key={sliderDetail._id} className="slider-item">
                <Box m={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleEditDetails(sliderDetail._id)}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleConfirmDelete(sliderDetail._id)}
                    style={{ marginLeft: "10px" }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>

                {sliderDetail.type === "image" ? (
                  <img
                    src={sliderDetail.src}
                    alt={sliderDetail.id}
                    className="slider-image"
                    //  style={{ maxWidth: '100%', maxHeight: '900px' }} // Adjust the max width and height as needed
                  />
                ) : (
                  <video
                    controls
                    className="slider-video"
                    //  style={{ maxWidth: '100%', maxHeight: '900px' }} // Adjust the max width and height as needed
                  >
                    <source src={sliderDetail.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {isLoading && (
          <Skeleton variant="rectangular" width="100%" animation="wave" />
        )}
      </Container>
    </>
  );
};

export default Sliders;
