import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Button,
  Tooltip,
} from "@mui/material";
import ViewOrders from "../viewOrder";
import Header from "../../component/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchOrdersAction,
  deleteOrdersAction,
  updateRowOrderAction,
} from "../../state/orders/ordersSlice";

import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";

const Orders = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const handleViewOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setIsDrawerOpen(true);
  };

  /*   const handleCloseDrawer = () => {
      setIsDrawerOpen(false);
      setSelectedOrderId(null);
    };*/
  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "clientName",
      header: "Name",
      size: 100,
    },
    {
      accessorKey: "deliveryAddress",
      header: "Delivery Address",
      size: 150,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 150,
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone",
      size: 150,
    },
    {
      accessorKey: "deliveryMethod",
      header: "Delivery Method",
      size: 150,
    },
    {
      accessorKey: "promoCode",
      header: "Promo Code",
      size: 150,
    },
    {
      accessorKey: "total",
      header: "Total Price",
      size: 100,
    },
    {
      accessorKey: "discount",
      header: "Discount",
      size: 100,
    },
    {
      header: "View Order",
      size: 150,
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Order">
            <Button
              color="success"
              onClick={() => handleViewOrder(row.original._id)}
            >
              View Order
            </Button>
          </Tooltip>
        </span>
      ),
    },
    {
      header: "Actions",
      key: "action",
      size: 100,
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.Details);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  // const [openDrawer, setOpenDrawer] = useState(false);
  // const [orderId, setOrderId] = useState(null);

  //  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    if (orders) {
      const data = orders.map((item) => ({ ...item, key: item.id }));
      setDataSource(data);
    }
  }, [orders]);

  const handleDeleteFeedback = async () => {
    if (orderToDelete) {
      await dispatch(deleteOrdersAction(orderToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchOrdersAction());
      setToastMessage("Order deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (feedbackId) => {
    setOrderToDelete(feedbackId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setOrderToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleRowOrderChange = (newData) => {
    // Dispatch the action to update the row order
    dispatch(updateRowOrderAction(newData))
      .then(() => {
        // If the action was successful, update the local state
        setDataSource(newData);
        dispatch(fetchOrdersAction());
      })
      .catch((error) => {
        console.error("Error updating row order:", error);
        // Handle the error if necessary
        // You might want to show a toast message or handle the error in some other way
      });
  };

  const table = useMaterialReactTable({
    enableColumnPinning: true,
    autoResetPageIndex: false,

    columns,
    data: dataSource,
    enableRowOrdering: true,
    enableSorting: false,

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });

  return (
    <>
      <ViewOrders
        handleClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        id={selectedOrderId}
      />

      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this order?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteFeedback} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={"bottom-left"}
      />
      <Box m="1rem 1.5rem">
        <Header title="Orders" subtitle="List of Orders" />

        <Box mt="10px">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Orders;
