import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Snackbar,
  useTheme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchTermsAction,
  deleteTermsAction,
  updateTermsAction,
  addTermsAction,
  resetState,
} from "../../state/terms/terms";
import Header from "../../component/Header";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const Terms = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [dataSource, setDataSource] = useState([]);

  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [detailsToDelete, setDetailsToDelete] = useState(null);

  const terms = useSelector((state) => state.terms.Details);
  const loading = useSelector((state) => state.terms.loading);
  const appErr = useSelector((state) => state.terms.appErr);
  const isSuccess = useSelector((state) => state?.terms.isSuccess);
  const Updated = useSelector((state) => state?.terms.Updated);
  const added = useSelector((state) => state.terms.added);

  useEffect(() => {
    dispatch(fetchTermsAction());
  }, [dispatch]);

  useEffect(() => {
    if (terms) {
      const data = terms.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [terms]);

  const handleDelete = async () => {
    if (detailsToDelete) {
      await dispatch(deleteTermsAction(detailsToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchTermsAction());
      setToastMessage("Details deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (id) => {
    setDetailsToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDetailsToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);
  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Details Added Successfully!");
    }
  }, [isSuccess, added]);
  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);
  const id = dataSource && dataSource.length > 0 ? dataSource[0]._id : null;

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      text: dataSource && dataSource.length > 0 ? dataSource[0].text : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        text: values.text,
      };

      // Conditionally dispatch update or add action
      if (id) {
        dispatch(updateTermsAction({ data, id }));
      } else {
        dispatch(addTermsAction(data));
      }

      console.log(values);
      dispatch(fetchTermsAction());
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this details?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />

      <Box m="1.5rem 2.5rem"></Box>
      <form onSubmit={formik.handleSubmit}>
        <Box m="1.5rem 2.5rem">
          <Box
            mb="1rem"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Header title="Terms of Use " />
            <Button
              variant="contained"
              onClick={() => handleConfirmDelete(id)}
              color="error"
              size="lg"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Box>
          <Box
            p={2}
            borderRadius={2}
            bgcolor={theme.palette.background.default}
            boxShadow={theme.shadows[3]}
          >
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Edit Details :
            </Typography>
            <hr style={{ border: "1px solid #f0f0f0", margin: "10px 0" }} />
            <Typography variant="h6" gutterBottom>
              Text: <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              width="100%"
              mb="1.5rem"
              sx={{
                "& .ql-editor": {
                  height: "200px", // Set the height of the editor
                  border: "1px solid #ccc", // Example border
                  borderRadius: "4px", // Example border radius
                  marginTop: "40px",
                },
              }}
            >
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                name="text"
                value={formik.values.text}
                onChange={(value) => formik.setFieldValue("text", value)}
                error={formik.touched.text && Boolean(formik.errors.text)}
              />
              {formik.touched.text && formik.errors.text && (
                <div style={{ color: "red" }}>{formik.errors.text}</div>
              )}
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Terms;
