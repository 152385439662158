import {
  Box,
  useTheme,
  Button,
  Typography,
  Drawer,
  Grid,
  Divider,
} from "@mui/material";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";

import { CloseFullscreen } from "@mui/icons-material";
import Header from "../../component/Header";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderAction } from "../../state/orders/ordersSlice";
import { fetchCategoriesAction } from "../../state/categories/categorySlice";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ViewOrders = ({ open, handleClose, id }) => {
  const [dataSource, setDataSource] = useState([]); //for cart

  const dispatch = useDispatch();
  const data = useSelector((state) => state?.orders?.data);
  console.log(data);
  const cartDetails = useSelector((state) => state?.orders?.data);
  const categories = useSelector((state) => state?.categories?.catDetails);

  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);
  const theme = useTheme();
  //const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchOrderAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (cartDetails) {
      const cartData = cartDetails?.cart?.map((item) => ({
        ...item,
        key: item.id,
      }));
      setDataSource(cartData);
    }
  }, [cartDetails]);

  const drawerStyle = {
    backgroundColor: theme.palette.background.alt,
    height: "1000vh",
  };
  const columns = [
    { id: "name", label: "Name", minWidth: 140 },
    { id: "category", label: "Category", minWidth: 110 },
    {
      id: "price",
      label: "Price",
      minWidth: 70,
    },
    {
      id: "quantity",
      label: "Quantity",
      minWidth: 70,
    },
    {
      id: "needCutlery",
      label: "Need Cutlery?",
      minWidth: 70,
    },
    {
      id: "extras",
      label: "Extras",
      minWidth: 150,
    },
    {
      id: "freeExtras",
      label: "Free Extras",
      minWidth: 170,
    },

    {
      id: "totalPrice",
      label: "Total Item Price",
      minWidth: 60,
    },
  ];

  const getCategoryName = (catId) => {
    const category =
      categories && categories.find((category) => category._id === catId);
    return category ? category.name : "N/A"; // Return category name if found, otherwise return "N/A"
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "83%" } }}
      >
        <Box sx={drawerStyle}>
          <Box
            p="1rem"
            sx={{
              top: 0,
              height: "50px",
              background: "white",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: 2,
              zIndex: 100,
              width: "100%",
              position: "fixed",
            }}
          >
            <Header subtitle="View Order Details" />
            <CloseFullscreen
              onClick={handleClose}
              color="secondary"
              sx={{ cursor: "pointer", zIndex: 100 }}
            />
          </Box>

          <Box m="5rem 1rem 1.5rem 1rem">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
                <Box
                  mt={1}
                  borderRadius={2}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom p={2}>
                    Cart Details:
                  </Typography>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="sticky table">
                      <TableHead
                        sx={{ backgroundColor: theme.palette.background.alt }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              fontWeight: "bold",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody>
                        {dataSource.map((dataItem, index) => (
                          <TableRow
                            key={dataItem.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {dataItem.name}
                            </TableCell>
                            <TableCell>
                              {getCategoryName(dataItem.catId)}
                            </TableCell>
                            <TableCell>
                              $
                              {dataItem.offerPrice !== null
                                ? dataItem.offerPrice
                                : dataItem.initialPrice}
                            </TableCell>
                            <TableCell>{dataItem.quantity}</TableCell>

                            <TableCell>
                              {" "}
                              {dataItem && dataItem.receiveNotifications ? (
                                <CheckCircleOutline
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <HighlightOff style={{ color: "red" }} />
                              )}
                            </TableCell>

                            <TableCell>
                              {dataItem.extras &&
                                dataItem.extras
                                  .filter((extra) => extra.isChecked)
                                  .map((extra) => (
                                    <div
                                      key={extra.name}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>{extra.name}:</span>{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        ${extra.price}
                                      </span>
                                    </div>
                                  ))}
                            </TableCell>
                            <TableCell>
                              {dataItem.ingredientsExtras &&
                                dataItem.ingredientsExtras.map(
                                  (ingredient, index) => (
                                    <div key={ingredient.name}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <span>
                                          {index + 1}. {ingredient.name}
                                        </span>
                                        <div>
                                          {ingredient.extras &&
                                            ingredient.extras.filter(
                                              (extra) => extra.isChecked
                                            ).length > 0 && (
                                              <ul>
                                                {ingredient.extras
                                                  .filter(
                                                    (extra) => extra.isChecked
                                                  )
                                                  .map((extra) => (
                                                    <li key={extra.name}>
                                                      {extra.name}
                                                    </li>
                                                  ))}
                                              </ul>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </TableCell>

                            <TableCell style={{ fontWeight: "bold" }}>
                              ${" "}
                              {dataItem.offerPrice !== null
                                ? dataItem.offerPrice * dataItem.quantity +
                                  (dataItem.extras
                                    ? dataItem.extras
                                        .filter((extra) => extra.isChecked)
                                        .reduce(
                                          (acc, curr) =>
                                            acc +
                                            curr.price * dataItem.quantity,
                                          0
                                        )
                                    : 0)
                                : dataItem.initialPrice * dataItem.quantity +
                                  (dataItem.extras
                                    ? dataItem.extras
                                        .filter((extra) => extra.isChecked)
                                        .reduce(
                                          (acc, curr) =>
                                            acc +
                                            curr.price * dataItem.quantity,
                                          0
                                        )
                                    : 0)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={4} />
                          <TableCell colSpan={4}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Total Items Price
                                  </TableCell>
                                  <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    ${data && data.itemsPrice.toFixed(2)}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Discount
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "red", fontWeight: "bold" }}
                                    align="right"
                                  >
                                    -$
                                    {(
                                      data &&
                                      data.itemsPrice &&
                                      data.discount &&
                                      (data.itemsPrice * data.discount) / 100
                                    )?.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    SubTotal
                                  </TableCell>
                                  <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    ${data && data.subtotal.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Tips Price
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "red", fontWeight: "bold" }}
                                    align="right"
                                  >
                                    +$
                                    {(
                                      data &&
                                      data.subtotal &&
                                      data.tipsPercentage &&
                                      (data.subtotal * data.tipsPercentage) /
                                        100
                                    )?.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Delivery Charge
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "red", fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {data && data.deliveryCost === 0
                                      ? "+$0.00"
                                      : "+" +
                                        Number(
                                          data && data.deliveryCost
                                        ).toFixed(2)}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Total Price
                                  </TableCell>
                                  <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    ${data && data.total.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Grid item xs={12} md={12}>
                  <Box
                    p={2}
                    mt={1}
                    borderRadius={2}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Customer Details:
                    </Typography>
                    <hr
                      style={{
                        border: "1px solid #f0f0f0",
                        margin: "10px 0",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                    <b>Name:</b>  {data && data.clientName}
                    </Typography>

                    <Typography variant="h6" gutterBottom mt={2}>
                    <b>Phone:</b>  {data && data.phoneNumber}
                    </Typography>
                    <Typography variant="h6" gutterBottom mt={2}>
                    <b>Email:</b>  {data && data.email}
                    </Typography>
                  </Box>
                </Grid>

                {data && data.deliveryMethod === "delivery" && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Box
                        p={2}
                        mt={2}
                        borderRadius={2}
                        bgcolor={theme.palette.background.default}
                        boxShadow={theme.shadows[3]}
                      >
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                          <b>Address Details:</b>
                        </Typography>
                        <hr
                          style={{
                            border: "1px solid #f0f0f0",
                            margin: "10px 0",
                          }}
                        />
                        <Typography variant="h6" gutterBottom>
                          <b>Address:</b> {data && data.deliveryAddress}
                        </Typography>

                        <Typography variant="h6" gutterBottom mt={2}>
                          <b>Details:</b> {data && data.addressDetails}
                        </Typography>
                        <Typography variant="h6" gutterBottom mt={2}>
                          <b>Comments:</b> {data && data.addressComment}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Box
                        p={2}
                        mt={2}
                        borderRadius={2}
                        bgcolor={theme.palette.background.default}
                        boxShadow={theme.shadows[3]}
                      >
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                          Date and Time:
                        </Typography>
                        <hr
                          style={{
                            border: "1px solid #f0f0f0",
                            margin: "10px 0",
                          }}
                        />
                        <Typography variant="h6" gutterBottom>
                        <b>Time:</b>  {data && data.chosenTime}
                        </Typography>

                        <Typography variant="h6" gutterBottom mt={2}>
                        <b>Date:</b>  {data && data.chosenDate}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={12}>
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={2}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Need Cutlery:
                    </Typography>
                    <hr
                      style={{
                        border: "1px solid #f0f0f0",
                        margin: "10px 0",
                      }}
                    />

                    <Typography variant="h6" gutterBottom mt={2}>
                      Need Cutlery? :{" "}
                      {data && data.needCutlery ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <HighlightOff style={{ color: "red" }} />
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={2}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      PromoCode
                    </Typography>
                    <hr
                      style={{
                        border: "1px solid #f0f0f0",
                        margin: "10px 0",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Code: {data && data.promoCode}
                    </Typography>

                    <Typography variant="h6" gutterBottom mt={2}>
                      Discount: %{data && data.discount}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={2}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Tips
                    </Typography>
                    <hr
                      style={{
                        border: "1px solid #f0f0f0",
                        margin: "10px 0",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Tips Percentage: %{data && data.tipsPercentage}
                    </Typography>
                    <Typography variant="h6" gutterBottom mt={2}>
                      Tips Amount: $
                      {Number(
                        (data && data.subtotal * data.tipsPercentage) / 100
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginBottom: "5rem" }}>
                  <Box
                    p={2}
                    mt={2}
                    borderRadius={2}
                    bgcolor={theme.palette.background.default}
                    boxShadow={theme.shadows[3]}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Restaurant Comments:
                    </Typography>
                    <hr
                      style={{
                        border: "1px solid #f0f0f0",
                        margin: "10px 0",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Comment: {data && data.restaurantComment}
                    </Typography>

                    <Typography variant="h6" gutterBottom mt={2}>
                      Notification? :{" "}
                      {data && data.receiveNotifications ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <HighlightOff style={{ color: "red" }} />
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m="4rem 1rem 1.5rem 1rem" height="100%">
        <MaterialReactTable table={table} />
      </Box>*/}
        </Box>
        <Box
          p={2}
          sx={{
            bottom: 0,
            height: "70px",
            textAlign: "center",
            background: "white",

            width: "100%",
            // position: "fixed",
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            style={{ marginRight: "20rem" }}
          >
            Cancel
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
export default ViewOrders;
