import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  DialogActions,
  Drawer,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import Header from "../../component/Header";
import { addSlidersAction, resetState } from "../../state/sliders/sliderSlice";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const AddSlider = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("No file chosen");

  const isSuccess = useSelector((state) => state.sliders.isSuccess);
  const added = useSelector((state) => state.sliders.added);
  const appErr = useSelector((state) => state.sliders.appErr);
  const loading = useSelector((state) => state.sliders.loading);

  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Slider Added Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, dispatch, loading, added]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    src: Yup.mixed().required("Image is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      type: "",
      src: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addSlidersAction(values));
      formik.resetForm();
      setFileName("No file chosen");
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  const imageStyle = {
    objectFit: "contain",
    marginBottom: theme.spacing(2),
    display: "block",
    margin: "0 auto",
    marginTop: "10px",
    maxWidth: "100%",
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "35%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="Sliders" subtitle="Add New Slider" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Slider Type:
                  </Typography>
                  <hr
                    style={{
                      border: "1px solid #f0f0f0",
                      margin: "10px 0",
                    }}
                  />
                  <RadioGroup
                    aria-label="slider-type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="image"
                      control={<Radio />}
                      label="Image"
                    />
                    <FormControlLabel
                      value="video"
                      control={<Radio />}
                      label="Video"
                    />
                    {formik.touched.type && formik.errors.type && (
                      <FormHelperText>{formik.errors.type}</FormHelperText>
                    )}
                  </RadioGroup>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Slider Image:
                  </Typography>
                  <hr
                    style={{
                      border: "1px solid #f0f0f0",
                      margin: "10px 0",
                    }}
                  />
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      formik.setFieldValue("src", acceptedFiles[0]);
                      setFileName(acceptedFiles[0].name);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept="image/*" />
                        <Button
                          variant="contained"
                          startIcon={<Upload />}
                          color="secondary"
                          style={{
                            marginTop: "18px",
                            marginBottom: "10px",
                          }}
                        >
                          Choose Image
                        </Button>
                      </div>
                    )}
                  </Dropzone>

                  {formik.values.src && (
                    <img
                      src={URL.createObjectURL(formik.values.src)}
                      alt="Selected Img"
                      style={imageStyle}
                    />
                  )}
                  <div style={{ color: "#d32f2f" }}>
                    {formik.touched.src && Boolean(formik.errors.src)}
                    {formik.touched.src && formik.errors.src}
                  </div>
                  <span>{fileName}</span>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddSlider;
