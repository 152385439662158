import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Typography,
  Grid,
  useTheme,
  DialogActions
} from "@mui/material";
import Header from "../../component/Header";
import {
  addCodeAction,
  resetState,
} from "../../state/promoCode/promoCodeSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const AddCodeForm = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.promoCode.isSuccess);
  const appErr = useSelector((state) => state.promoCode.appErr);
  const added = useSelector((state) => state.promoCode.added);
  const loading = useSelector((state) => state.promoCode.loading);

  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Promo Code Added Successfully!");
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, added]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    promoCode: Yup.string().required("Code is required"),
    startDate: Yup.date().required("Start Date is required"),
    expiryDate: Yup.date().required("Expiry Date is required"),
    discount: Yup.number().required("Discount is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      promoCode: "",
      startDate: "",
      expiryDate: "",
      discount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addCodeAction(values));
      console.log(values);
      formik.resetForm();
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }}
        PaperProps={{
          sx: {
            width: "45%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb="1rem">
              <Header title="Promo Code" subtitle="Add New Code" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Promo Code :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Code: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="promoCode"
                    name="promoCode"
                    label="Code"
                    variant="outlined"
                    fullWidth
                    value={formik.values.promoCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.promoCode &&
                      Boolean(formik.errors.promoCode)
                    }
                    helperText={
                      formik.touched.promoCode && formik.errors.promoCode
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Start Date :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Date: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="startDate"
                    name="startDate"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Expiry Date :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Date: <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextField
                    id="expiryDate"
                    name="expiryDate"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={formik.values.expiryDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.expiryDate &&
                      Boolean(formik.errors.expiryDate)
                    }
                    helperText={
                      formik.touched.expiryDate && formik.errors.expiryDate
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Discount :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Discount %: <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextField
                    id="discount"
                    name="discount"
                    label="Discount"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formik.values.discount}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.discount && Boolean(formik.errors.discount)
                    }
                    helperText={
                      formik.touched.discount && formik.errors.discount
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddCodeForm;
