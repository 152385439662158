import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Tooltip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import Header from "../../component/Header";
import {
  fetchUsersAction,
  deleteUsersAction,
} from "../../state/auth/authSlices";

import EditAdmin from "./editAdmin";
import AddAdminForm from "./addAdminForm";

const Admins = () => {
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const users = useSelector((state) => state.users.users);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    dispatch(fetchUsersAction());
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      const data = users.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [users]);

  const openHandler = () => {
    setOpenDrawer((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };

  const handleDeleteCategory = async () => {
    if (userToDelete) {
      await dispatch(deleteUsersAction(userToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchUsersAction());
      setToastMessage("Category deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (id) => {
    setUserToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setUserToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleEditDetails = (id) => {
    openEditHandler();
    setEditId(id);
  };
  const handleRowOrderChange = (newData) => {
    // Update the row order directly in the Redux store
    //   dispatch(updateRowOrderAction(newData));
    setDataSource(newData); // Optionally, you can also update local state if needed
  };

  const columns = [
    {
      accessorKey: "_id",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 100,
    },
    {
      accessorKey: "role",
      header: "Role",
      size: 100,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 100,
    },

    {
      header: "Actions",
      size: 50,
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="error"
              onClick={() => handleEditDetails(row.original._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: dataSource,
    enableRowOrdering: true,
    enableSorting: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });
  return (
    <>
      <AddAdminForm handleClose={openHandler} open={openDrawer} />
      <EditAdmin
        handleClose={openEditHandler}
        open={openEditDrawer}
        id={editId}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this User?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCategory} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={"bottom-left"}
      />

      <Box m="1.5rem 2.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Users" subtitle="List of Users" />
          <Button
            variant="contained"
            onClick={openHandler}
            color="secondary"
            size="lg"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
        </div>
        <Box mt="40px" height="75vh">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Admins;
