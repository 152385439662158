import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  updateDetailsAction,
  fetchDetailAction,
  resetState,
} from "../../state/extras/extrasSlice"; // Update import statements
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const EditExtras = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();


  const loading = useSelector((state) => state.extras.loading);
  const extra = useSelector((state) => state?.extras?.data);
  const isSuccess = useSelector((state) => state?.extras.isSuccess);
  const Updated = useSelector((state) => state?.extras.Updated);
  const appErr = useSelector((state) => state.extras.appErr);

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailAction(id));
    }
  }, [dispatch, id,isSuccess]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);
  
  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    price: Yup.number().required(" price is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: extra?.name || "", // Ensure that the initial value is not undefined
      price: extra?.price || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        name: values?.name,
        price: values?.price,
      };
      dispatch(updateDetailsAction({ data, id }));
      console.log(values);
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
            sx: {
              width: "40%", // Default width
              "@media (max-width: 700px)": {
                width: "70%", // Width set to 70% for screens <= 700px
              },
            },
          }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb="1rem">
              <Header title="Extras Details" subtitle="Add New Extras" />
            </Box>
            <Grid container >
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Extras Name :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                     Name: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                mt={3}
                  p={2}
                  mb={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Extras Price :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Price: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="price"
                    name="price"
                    label="Price"
                    variant="outlined"
                    fullWidth
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
            
          <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          size="small"
          style={{ marginRight:"5px" }}
        >
          Cancel
        </Button>
        <Button
        type="submit"
        variant="contained"
        color="secondary"
        disabled={loading}
        size="small"
      >
        {!loading ? "Submit" : <Loader />}
      </Button>
          </Box>

        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditExtras;
