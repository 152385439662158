import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../component/Navbar";
import Sidebar from "../../component/Sidebar";
import { fetchUserDetailsAction } from "../../state/auth/authSlices";
import { fetchDetailAction } from "../../state/token/tokenSlice";
import { logoutAction } from "../../state/auth/authSlices";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 750px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const user = useSelector((state) => state.users);
  const details = useSelector((state) => state.users.userAuth?._id);

  const { userAuth } = user;
  const theme = useTheme();
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.token?.data);
  const appErr = useSelector((state) => state.token?.appErr);
console.log(token)
  // Fetch user details
  useEffect(() => {
    dispatch(fetchUserDetailsAction(details));
    dispatch(fetchDetailAction());
  }, [dispatch, details]);

  useEffect(() => {
    if (!token && appErr) {
      const timeout = setTimeout(() => {
        dispatch(logoutAction());
      }, 8000);

      // Clear the timeout if the component unmounts or if token becomes available
      return () => clearTimeout(timeout);
    }
  }, [token, appErr, dispatch]);
  return (
    <Box
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
      backgroundColor={theme.palette.background.alt}
    >
      <Sidebar
        user={userAuth?.name}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1} style={{ overflowX: "auto" }}>
        <Navbar
          user={userAuth?.name}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <Outlet isSidebarOpen={isSidebarOpen} />
      </Box>
    </Box>
  );
};

export default Layout;
