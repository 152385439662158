import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../utils/config";
const resetEditAction = createAction("contacts/reset");

//add action
export const addContactsAction = createAsyncThunk(
  "contacts/add",
  async (contact, { rejectWithValue, getState, dispatch }) => {
    const formData = new FormData();

    // Append simple fields to FormData
    formData.append("name", contact.name);
    formData.append("address", contact.address);
    formData.append("phone", contact.phone);

    // Append languages array to FormData
    contact.languages.forEach((language, index) => {
      formData.append(`languages[${index}][name]`, language.name);
      formData.append(`languages[${index}][shortcut]`, language.shortcut);
      formData.append(
        `languages[${index}][default_lang]`,
        language.default_lang
      );
    });

    // Append socials array to FormData
    contact.socials.forEach((social, index) => {
      formData.append(`socials[${index}][name]`, social.name);
      formData.append(`socials[${index}][link]`, social.link);
    });

    // Append logo file to FormData
    formData.append("logo", contact.logo);

    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    //http call
    try {
      const { data } = await axios.post(`${baseUrl}/contacts`, contact, config);
      dispatch(fetchContactsAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchContactsAction = createAsyncThunk(
  "contacts/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/contacts`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchContactAction = createAsyncThunk(
  "contacts/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/contacts/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateContactsAction = createAsyncThunk(
  "contacts/update",
  async ({ data, id }, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // HTTP call
    try {
      const { data: contact } = await axios.put(
        `${baseUrl}/contacts/${id}`,
        data, // Use the updated values from the form
        config
      );

      // Dispatch
      dispatch(resetEditAction());
      dispatch(fetchContactsAction());
      return contact;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteContactsAction = createAsyncThunk(
  "contacts/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/contacts/${id}`, config);
      dispatch(fetchContactsAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const contactsSlices = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addContactsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addContactsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addContactsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchContactsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchContactsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchContactsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteContactsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteContactsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update categories state after deletion if needed
    });
    builder.addCase(deleteContactsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateContactsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetEditAction, (state, action) => {
      state.isEdited = true;
    });
    builder.addCase(updateContactsAction.fulfilled, (state, action) => {
      state.Updated = action?.payload;
      state.loading = false;
      console.log(action.payload);
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateContactsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // details
    builder.addCase(fetchContactAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchContactAction.fulfilled, (state, action) => {
      state.loading = false;
      state.contact = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchContactAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
  },
});

export default contactsSlices.reducer;
