import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  DialogActions,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import { updatePasswordAction, resetState } from "../../state/auth/authSlices";
//import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { logoutAction } from "../../state/auth/authSlices";
import Loader from "../../component/loader";

const ChangePassword = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSuccess = useSelector((state) => state.users.isSuccess);
  const updated = useSelector((state) => state.users.passwordUpdated);
  const appErr = useSelector((state) => state.users.appErr);
  const serverErr = useSelector((state) => state.users.serverErr);
  const loading = useSelector((state) => state.users.loading);
  const isPasswordUpdated = useSelector(
    (state) => state.users.isPasswordUpdated
  );

  useEffect(() => {
    if (isSuccess && updated) {
      toast.success("Password Updated Successfully!");
    }
    if (appErr || serverErr) {
      toast.error("Something Went Wrong!");
    }
    // Dispatch logout action if password is updated
    if (isPasswordUpdated) {
      dispatch(logoutAction());
    }
  }, [
    isSuccess,
    appErr,
    dispatch,
    loading,
    updated,
    isPasswordUpdated,
    serverErr,
  ]);

  // Define validation schema
  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required("Name is required"),
  //   email: Yup.string().required("Email is required"),
  //   password: Yup.string().required("Email is required"),
  // });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updatePasswordAction(values));
      formik.resetForm();
      console.log(values);
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "35%", // Default width
            "@media (max-width: 900px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="Users" subtitle="Change Password" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Old Password :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Password: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="old_password"
                    name="old_password"
                    label="Old Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={formik.values.old_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.old_password &&
                      Boolean(formik.errors.old_password)
                    }
                    helperText={
                      formik.touched.old_password && formik.errors.old_password
                    }
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    New Password :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Password: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="new_password"
                    name="new_password"
                    label="New Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.new_password &&
                      Boolean(formik.errors.new_password)
                    }
                    helperText={
                      formik.touched.new_password && formik.errors.new_password
                    }
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default ChangePassword;
