import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../utils/config";
const resetEditAction = createAction("colors/reset");

//add action
export const addColorsAction = createAsyncThunk(
  "colors/add",
  async (colors, { rejectWithValue, getState, dispatch }) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    //http call
    try {
      const { data } = await axios.post(`${baseUrl}/colors/`, colors, config);
      dispatch(fetchColorsAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchColorsAction = createAsyncThunk(
  "colors/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/colors`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchColorAction = createAsyncThunk(
  "colors/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/colors/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateColorsAction = createAsyncThunk(
  "colors/update",
  async ({ data, id }, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // HTTP call
    try {
      const { data: color } = await axios.put(
        `${baseUrl}/colors/${id}`,
        { data }, // Use the updated values from the form
        config
      );

      // Dispatch
      dispatch(resetEditAction());
      dispatch(fetchColorsAction());
      return color;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteColorsAction = createAsyncThunk(
  "colors/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/colors/${id}`, config);
      dispatch(fetchColorsAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const colorsSlices = createSlice({
  name: "colors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addColorsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addColorsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addColorsAction.rejected, (state, action) => {
      //  console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchColorsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchColorsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchColorsAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteColorsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteColorsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update categories state after deletion if needed
    });
    builder.addCase(deleteColorsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateColorsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetEditAction, (state, action) => {
      state.isEdited = true;
    });
    builder.addCase(updateColorsAction.fulfilled, (state, action) => {
      state.Updated = action?.payload;
      state.loading = false;

      console.log(action.payload);
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateColorsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //category details
    builder.addCase(fetchColorAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchColorAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchColorAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
  },
});

export default colorsSlices.reducer;
