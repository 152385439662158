import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../component/Header";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  fetchContactsAction,
  deleteContactsAction,
} from "../../state/contacts/contactsSlice";
import AddContactForm from "./addContact";
import EditContact from "./editContact";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";

const Contacts = () => {
  const dispatch = useDispatch();

  const contacts = useSelector((state) => state.contacts.Details);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    dispatch(fetchContactsAction());
  }, [dispatch]);

  useEffect(() => {
    if (contacts) {
      const data = contacts.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [contacts]);

  const handleDeleteContact = async () => {
    if (contactToDelete) {
      await dispatch(deleteContactsAction(contactToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchContactsAction());
      setToastMessage("Contact deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (contactId) => {
    setContactToDelete(contactId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setContactToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleEditContact = (id) => {
    openEditHandler();
    setEditId(id);
  };
  const openHandler = () => {
    setOpenDrawer((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditModal((o) => !o);
  };
  const handleRowOrderChange = (newData) => {
    // Update the row order directly in the Redux store
    //   dispatch(updateRowOrderAction(newData));
    setDataSource(newData); // Optionally, you can also update local state if needed
  };

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 100,
    },
    {
      accessorKey: "logo",
      header: "Logo",
      size: 100,
      Cell: ({ row }) => (
        <img
          src={row.original.logo}
          alt="Logo"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      accessorKey: "address",
      header: "Address",
      size: 100,
    },
    {
      accessorKey: "phone",
      header: "Phone",
      size: 100,
    },

    {
      header: "Actions",
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="error"
              onClick={() => handleEditContact(row.original._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: dataSource,
    enableRowOrdering: true,
    enableSorting: true,

    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(255,210,244,0.1)"
            : "rgba(0,0,0,0.1)",
      }),
    }),

    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      row.original.name ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // Two columns
            gap: "20px", // Add gap between grid items
            marginTop: "20px", // Add margin at the top
          }}
        >
          {/* Languages Section */}
          <div style={{ width: "100%" }}>
            {" "}
            {/* Set width to 100% */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Languages
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Two columns
                gap: "20px", // Add gap between grid items
                marginTop: "10px", // Add margin at the top
              }}
            >
              {" "}
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Name:
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Shortcut:
              </Typography>
            </Box>
            {row.original.languages.map((language) => (
              <Box
                key={language._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr", // Two columns
                  gap: "20px", // Add gap between grid items
                  marginTop: "10px", // Add margin at the top
                }}
              >
                <Typography variant="subtitle2">{language.name}</Typography>
                <Typography variant="subtitle2">{language.shortcut}</Typography>
              </Box>
            ))}
          </div>

          {/* Socials Section */}
          <div style={{ width: "100%" }}>
            {" "}
            {/* Set width to 100% */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Socials
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Two columns
                gap: "20px", // Add gap between grid items
                marginTop: "10px", // Add margin at the top
              }}
            >
              {" "}
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Name:
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Link:
              </Typography>
            </Box>
            {row.original.socials.map((social) => (
              <Box
                key={social._id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr", // Two columns
                  gap: "20px", // Add gap between grid items
                  marginTop: "10px", // Add margin at the top
                }}
              >
                <Typography variant="subtitle2">{social.name}</Typography>
                <Typography variant="subtitle2">{social.link}</Typography>
              </Box>
            ))}
          </div>
        </Box>
      ) : null,

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });
  return (
    <>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this contact?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteContact} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={"bottom-left"}
      />
      <EditContact
        handleClose={openEditHandler}
        open={openEditModal}
        id={editId}
      />
      <Box m="1.5rem 2.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Contacts" subtitle="List of Contacts" />
          <Button
            variant="contained"
            onClick={openHandler}
            size="lg"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
          <AddContactForm handleClose={openHandler} open={openDrawer} />
        </div>
        <Box mt="40px" height="75vh">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Contacts;
