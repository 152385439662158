import React, {  useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import {
  fetchItemsAction,
 fetchItemAction
} from "../../state/items/itemsSlice";
import { fetchCategoriesAction } from "../../state/categories/categorySlice";
import { fetchDetailsAction } from "../../state/extras/extrasSlice";
import { useDispatch, useSelector } from "react-redux";
const Product = ({openDrawer, handleCloseDrawer ,id}) => {

  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(fetchItemsAction());
    dispatch(fetchCategoriesAction());
    dispatch(fetchDetailsAction());
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(fetchItemAction(id));
    }
  }, [dispatch, id]);

  const items = useSelector((state) => state.items.item);

  const imageStyle = {
   // objectFit: "contain",
    //marginBottom: theme.spacing(2),
 //   display: "block",
    margin: "0 auto",
  };

  const modalContentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: theme.spacing(2),
  };




  return (
    <React.Fragment>
  

      <Dialog
        open={openDrawer}
        onClose={handleCloseDrawer}
        maxWidth="sm"
        sx={{ "& .MuiDialog-paper": { minWidth: "400px" } }}
      >
      
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent style={modalContentStyle}>
          <img src={items && items.main_image} alt={items && items.name} height="200px" style={imageStyle} />
          <Typography
            variant="body1"
            fontWeight="bold"
            style={{ marginTop: "0.5rem", marginRight: "7rem" }}
          >
            Initial Price:{" "}
            <span style={{ marginLeft: "1rem", float: "right" }}>
              ${items && items.initialPrice}
            </span>
          </Typography>
          {items && items.offerPrice !== null && (
            <Typography variant="body1" fontWeight="bold">
              Offer Price:{" "}
              <span style={{ marginLeft: "1rem" }}>${items.offerPrice}</span>
            </Typography>
          )}

          <Typography variant="body1" fontWeight="bold">
            Ingredients:
          </Typography>
          {items && items.ingredients.length === 0 ? (
            <Typography>No ingredients</Typography>
          ) : (
            <ul>
              {items && items.ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ul>
          )}
          <Box
            display="flex"
            alignItems="center"
            bgcolor={theme.palette.background.alt}
            p={2}
            mt={2}
            borderRadius={theme.shape.borderRadius}
            boxShadow={theme.shadows[3]}
            width="100%"
          >
            <IconButton>
              {items && items.favorites > 0 ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
            <Typography>{items && items.favorites} Favorites</Typography>
          </Box>
          <Box
            bgcolor={theme.palette.background.alt}
            p={2}
            mt={2}
            borderRadius={theme.shape.borderRadius}
            boxShadow={theme.shadows[3]}
            width="100%"
          >
            <Typography variant="h6" fontWeight="bold">
              Ingredients Extras:
            </Typography>
            {items && items.ingredientsExtras.length === 0 ? (
              <Typography>No Ingredients Extras</Typography>
            ) : (
              <ul>
                {items && items.ingredientsExtras.map((ingredient, index) => (
                  <li key={index}>
                    <Typography variant="body1" fontWeight="bold">
                      {items && ingredient.name}
                    </Typography>
                    <ul>
                      {items && ingredient.extras.map((extra, idx) => (
                        <li key={idx}>
                          <Typography>{extra.name}</Typography>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </Box>
          <Box
            bgcolor={theme.palette.background.alt}
            p={2}
            mt={2}
            borderRadius={theme.shape.borderRadius}
            boxShadow={theme.shadows[3]}
            width="100%"
          >
            <Typography variant="h6" fontWeight="bold">
              Extras:
            </Typography>
            {items && items.extras.length === 0 ? (
              <Typography>No Extras</Typography>
            ) : (
              items && items.extras && items.extras.map((extra, index) => (
                <Box key={index} display="flex" justifyContent="space-between">
                  <Typography>{extra.name}</Typography>
                  <Typography fontWeight="bold">
                    ${extra.price}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        </DialogContent>
           
        <DialogActions>
          <Button onClick={handleCloseDrawer} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default Product;
