import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import Header from "../../component/Header";
import {
  addCategoryAction,
  resetState,
  fetchCategoriesAction,
} from "../../state/categories/categorySlice";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import { baseUrl } from "../../utils/config";
import Loader from "../../component/loader";

const AddCategoryForm = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("No file chosen");

  const isSuccess = useSelector((state) => state.categories.isSuccess);
  const added = useSelector((state) => state.categories.added);
  const appErr = useSelector((state) => state.categories.appErr);
  const loading = useSelector((state) => state.categories.loading);

  useEffect(() => {
    // Fetch updated categories from the server
    const fetchUpdatedCategories = () => {
      axios
        .get(`${baseUrl}/categories`)
        .then((response) => {
          const updatedCategories = response.data;
          dispatch(fetchCategoriesAction(updatedCategories));
          dispatch(resetState());
        })
        .catch((error) => {
          console.error("Error fetching updated categories:", error);
        });
    };
    if (isSuccess && added) {
      toast.success("Category Added Successfully!");

      fetchUpdatedCategories();
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, added, dispatch]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    main_image: Yup.mixed().required("Image is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      name: "",
      main_image: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addCategoryAction(values));
      formik.resetForm();
      setFileName("No file chosen");

      setSubmitting(false);
      //fetchUpdatedCategories();
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 1000);
    },
  });
  const imageStyle = {
    objectFit: "contain",
    marginBottom: theme.spacing(2),
    display: "block",
    margin: "0 auto",
    marginTop: "10px",
    maxWidth: "100%",
  };
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "35%", // Default width
            "@media (max-width: 900px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="Categories" subtitle="Add Categories" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Category Name :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Name: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Category Image :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Image: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      formik.setFieldValue("main_image", acceptedFiles[0]);
                      setFileName(acceptedFiles[0].name);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept="image/*" />
                        <Button
                          variant="contained"
                          startIcon={<Upload />}
                          color="secondary"
                          style={{ marginTop: "18px", marginBottom: "10px" }}
                        >
                          Choose Image
                        </Button>
                      </div>
                    )}
                  </Dropzone>

                  {formik.values.main_image && (
                    <img
                      src={URL.createObjectURL(formik.values.main_image)}
                      alt="Selected Img"
                      style={imageStyle}
                    />
                  )}
                  <div style={{ color: "#d32f2f" }}>
                    {formik.touched.main_image &&
                      Boolean(formik.errors.main_image)}
                    {formik.touched.main_image && formik.errors.main_image}
                  </div>
                  <span>{fileName}</span>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddCategoryForm;
