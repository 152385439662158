import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Typography,
  Grid,
  DialogActions,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  updateAllergensAction,
  fetchAllergenAction,
  resetState,
} from "../../state/allergens/allergensSlice"; // Update import statements
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const EditAllergen = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const appErr = useSelector((state) => state.allergens.appErr);
  const loading = useSelector((state) => state.allergens.loading);
  const allergen = useSelector((state) => state.allergens.allergen);
  const isSuccess = useSelector((state) => state?.allergens.isSuccess);
  const Updated = useSelector((state) => state?.allergens.Updated);

  useEffect(() => {
    if (id) {
      dispatch(fetchAllergenAction(id));
    }
  }, [dispatch, id, isSuccess]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: allergen?.name || "", // Ensure that the initial value is not undefined
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        name: values?.name,
      };
      dispatch(updateAllergensAction({ data, id }));
      console.log(values);
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "35%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="Allergens Details" subtitle="Edit Allergen" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Allergen Name :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Name: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditAllergen;
