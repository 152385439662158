import React from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import MapIcon from "@mui/icons-material/Map";
import Header from "../../component/Header";
import DescriptionIcon from "@mui/icons-material/Description";
//import NavigationIcon from "@mui/icons-material/Navigation";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoIcon from "@mui/icons-material/Info"; // Import icons for About Us
import PolicyIcon from "@mui/icons-material/Policy";
import TranslateIcon from "@mui/icons-material/Translate"; // Import icons for Privacy Policy
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    //justifyContent: "space-between",
    width: "100%", // Adjust the maximum width as needed
    margin: "0 auto",
  };

  const boxStyle = {
    textAlign: "center",
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    transition:
      "box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out", // Add transition for smooth hover effect
    "&:hover": {
      boxShadow: "5px 8px 11px rgba(0, 0, 0, 0.1)", // Apply box shadow on hover
      cursor: "pointer",
      backgroundColor: theme.palette.background.alt,
    },
  };

  const iconStyle = {
    fontSize: "48px",
    color: "#1976d2", // Adjust the color as needed
    marginBottom: "8px",
  };
  return (
    <React.Fragment>
      <Box m="1.5rem 2.5rem">
        <Header title="General Settings" />

        <hr style={{ width: "98%", borderTop: "1px solid #ccc" }} />

        <Box m={3} display="flex" alignItems="center">
          {" "}
          <Header subtitle="About Company Settings" />
          <hr
            style={{
              border: "none",
              borderBottom: "2px solid #ccc",
              flex: "1",
              marginLeft: "16px",
              marginBottom: "1px",
            }}
          />{" "}
        </Box>

        <Grid container spacing={2} sx={containerStyle} mt={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../contacts")}>
              <SettingsIcon sx={iconStyle} />
              <Typography variant="subtitle1" sx={{ textDecoration: "none" }}>
                Company Settings
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../aboutus")}>
              <InfoIcon sx={iconStyle} />
              <Typography variant="subtitle1">About Us</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../admins")}>
              <AdminPanelSettingsIcon sx={iconStyle} />
              <Typography variant="subtitle1">Users</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../workingHours")}>
              <QueryBuilderIcon sx={iconStyle} />
              <Typography variant="subtitle1">Working Hours</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../privacy")}>
              <PolicyIcon sx={iconStyle} />
              <Typography variant="subtitle1">Privacy Policy</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../terms")}>
              <DescriptionIcon sx={iconStyle} />
              <Typography variant="subtitle1">Terms of Use</Typography>
            </Box>
          </Grid>
        </Grid>

        <Box m={3} display="flex" alignItems="center">
          {" "}
          <Header subtitle="Website View Settings" />
          <hr
            style={{
              border: "none",
              borderBottom: "2px solid #ccc",
              flex: "1",
              marginLeft: "16px",
              marginBottom: "1px",
            }}
          />{" "}
        </Box>

        <Grid container spacing={2} sx={containerStyle} mt={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../maps")}>
              <MapIcon sx={iconStyle} />
              <Typography variant="subtitle1">Company Maps</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../colors")}>
              <ColorLensIcon sx={iconStyle} />
              <Typography variant="subtitle1">View Colors</Typography>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
        <Box sx={boxStyle} onClick={() => navigate('../navItems')}>
          <NavigationIcon
          sx={iconStyle}
          />
          <Typography variant="subtitle1">Navigation Items</Typography>
        </Box>
      </Grid>*/}

          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../data")}>
              <AttachMoneyIcon sx={iconStyle} />
              <Typography variant="subtitle1">Pricing Data</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={boxStyle} onClick={() => navigate("../translation")}>
              <TranslateIcon sx={iconStyle} />
              <Typography variant="subtitle1">Translation</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Settings;
