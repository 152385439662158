import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  //Button,
  //  Snackbar,
  Tooltip,
  IconButton,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
} from "@mui/material";
//import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import {
  fetchColorsAction,
  // deleteColorsAction,
} from "../../state/colors/colorsSlice";
//import AddColor from "./addColor";
import EditColor from "./editColor";
import Header from "../../component/Header";
import ColorBox from "../../component/ColorBox";

const Colors = () => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  // const [toastMessage, setToastMessage] = useState("");
  // const [openToast, setOpenToast] = useState(false);
  //  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  // const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  // const [detailsToDelete, setDetailsToDelete] = useState(null);

  const colors = useSelector((state) => state.colors.Details);
  useEffect(() => {
    dispatch(fetchColorsAction());
  }, [dispatch]);

  useEffect(() => {
    if (colors) {
      const dataa = colors.map((item) => ({ ...item, id: item.id }));
      setDataSource(dataa);
    }
  }, [colors]);

  // const handleDelete = async () => {
  //   if (detailsToDelete) {
  //     await dispatch(deleteColorsAction(detailsToDelete));
  //     setDeleteConfirmationOpen(false);
  //     await dispatch(fetchColorsAction());
  //     setToastMessage("Language deleted successfully");
  //     setOpenToast(true);
  //   }
  // };

  // const handleConfirmDelete = (id) => {
  //   setDetailsToDelete(id);
  //   setDeleteConfirmationOpen(true);
  // };

  // const handleCloseDeleteConfirmation = () => {
  //   setDetailsToDelete(null);
  //   setDeleteConfirmationOpen(false);
  // };

  const handleEditDetails = (id) => {
    setOpenEditModal(true);
    setEditId(id);
  };

  // const handleToastClose = () => {
  //   setOpenToast(false);
  // };

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 50,
    },
    {
      accessorKey: "main_color",
      header: "Main Color",
      size: 50,
      Cell: ({ row }) => <ColorBox color={row.original.main_color} />,
    },
    {
      accessorKey: "secondary_color",
      header: "Secondary Color",
      size: 50,
      Cell: ({ row }) => <ColorBox color={row.original.secondary_color} />,
    },
    {
      accessorKey: "footer_color",
      header: "Footer Color",
      size: 50,
      Cell: ({ row }) => <ColorBox color={row.original.footer_color} />,
    },
    {
      accessorKey: "footer_bar_color",
      header: "Footer Bar Color",
      size: 50,
      Cell: ({ row }) => <ColorBox color={row.original.footer_bar_color} />,
    },
    {
      accessorKey: "initial_price_color",
      header: "Initial Price Color",
      size: 50,
      Cell: ({ row }) => <ColorBox color={row.original.initial_price_color} />,
    },
    {
      accessorKey: "offer_price_color",
      header: "Offer Price Color",
      size: 50,
      Cell: ({ row }) => <ColorBox color={row.original.offer_price_color} />,
    },
    {
      header: "Actions",
      Cell: ({ row }) => (
        <span>
          {/* <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>*/}
          <Tooltip title="Edit">
            <IconButton
              color="error"
              onClick={() => handleEditDetails(row.original._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: dataSource,
   
  });

  return (
    <>
      {/*  <AddColor handleClose={() => setOpenModal(false)} open={openModal} />*/}
      <EditColor
        handleClose={() => setOpenEditModal(false)}
        open={openEditModal}
        id={editId}
      />
      {/*  <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this allergen?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />*/}

      <Box m="1.5rem 2.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Colors" subtitle="List of Colors" />
          {/*  <Button
            variant="contained"
            onClick={() => setOpenModal(true)}
            color="secondary"
            size="lg"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>*/}
        </div>

        <Box mt="40px">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Colors;
