import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Header from "../../component/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItemsAction,
  deleteItemsAction,
} from "../../state/items/itemsSlice";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchCategoriesAction } from "../../state/categories/categorySlice";
import { fetchDetailsAction } from "../../state/extras/extrasSlice";
import { fetchDataAction } from "../../state/ingredientsExtras/ingredientsExtrasSlice";
import Product from "../itemDetails";
import AddItem from "./addItem";
import EditItem from "./editItem";
const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchItemsAction());
    dispatch(fetchCategoriesAction());
    dispatch(fetchDetailsAction());
    dispatch(fetchDataAction());
  }, [dispatch]);

  const items = useSelector((state) => state.items.Details?.items);
  const categories = useSelector((state) => state.categories.catDetails);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (items) {
      const data = items.map((item) => ({ ...item, id: item.id }));
      setDataSource(data);
    }
  }, [items]);

  // const updatedDataSource = dataSource.map((item) => {
  //   const categoryName = categoryLookup[item.catId];

  //   return {
  //     ...item,
  //     catId: categoryName !== undefined ? categoryName : "Unknown Category",
  //   };
  // });

  useEffect(() => {
    if (items && categories) {
      const categoryLookup = {};
      categories &&
        categories.forEach((category) => {
          categoryLookup[category._id] = category.name;
        });
      const updatedData = items.map((item) => {
        const categoryName = categoryLookup[item.catId] || "Unknown Category";
        return {
          ...item,
          catId: categoryName,
        };
      });
      setDataSource(updatedData);
    }
  }, [items, categories]);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const handleDeleteItem = async () => {
    if (itemToDelete) {
      await dispatch(deleteItemsAction(itemToDelete));
      setDeleteConfirmationOpen(false);
      await dispatch(fetchItemsAction());
      await dispatch(fetchCategoriesAction());
      setToastMessage("Item deleted successfully");
      setOpenToast(true);
    }
  };

  const handleConfirmDelete = (id) => {
    setItemToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openHandler = () => {
    setIsDrawerOpen((o) => !o);
  };
  const openEditHandler = () => {
    setOpenEditDrawer((o) => !o);
  };

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editId, setEditId] = useState(null);
  const handleEditDetails = (id) => {
    openEditHandler();
    setEditId(id);
  };

  const handleViewItem = (id) => {
    setSelectedItemId(id);
    setIsOpenDrawer(true);
  };

  const columns = [
    {
      accessorKey: "catId",
      header: "Category",
      size: 50,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 100,
    },
    {
      accessorKey: "main_image",
      header: "Image",
      size: 100,
      Cell: ({ row }) => (
        <img
          src={row.original.main_image}
          alt=""
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      accessorKey: "initialPrice",
      header: "Initial Price",
      size: 100,
    },
    {
      accessorKey: "offerPrice",
      header: "Offer Price",
      size: 100,
    },
    {
      header: "View Details",
      size: 100,
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Order">
            <Button
              color="success"
              onClick={() => handleViewItem(row.original._id)}
            >
              View Details
            </Button>
          </Tooltip>
        </span>
      ),
    },
    {
      header: "Actions",
      size: 100,
      Cell: ({ row }) => (
        <span>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleConfirmDelete(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="error"
              onClick={() => handleEditDetails(row.original._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];
  const handleRowOrderChange = (newData) => {
    // Update the row order directly in the Redux store
    //   dispatch(updateRowOrderAction(newData));
    setDataSource(newData); // Optionally, you can also update local state if needed
  };

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: dataSource,
    enableRowOrdering: true,

    enableSorting: true,

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...dataSource];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );
          handleRowOrderChange(newData);
        }
      },
    }),
  });

  return (
    <>
      <AddItem handleClose={openHandler} open={isDrawerOpen} />
      <Product
        handleCloseDrawer={() => setIsOpenDrawer(false)}
        openDrawer={isOpenDrawer}
        id={selectedItemId}
      />
      <EditItem
        handleClose={openEditHandler}
        open={openEditDrawer}
        id={editId}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteItem} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom-left"}
      />

      <Box m="1rem 1.5rem">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Header title="Items" subtitle="See your list of items." />
          <Button
            variant="contained"
            onClick={openHandler}
            color="secondary"
            size="lg"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add New Item
          </Button>
        </div>

        <Box mt="10px" height="100%">
          <Button
            variant="contained"
            onClick={() => navigate("../sorting")}
            color="primary"
            size="lg"
            // startIcon={<AddCircleOutlineIcon />}
          >
            Sorting
          </Button>
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

export default Products;
