import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Box,useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import {
  updateDetailsAction,
  resetState,
  fetchDetailsAction,
} from "../../state/workingHours/workingSlice"; // Update the import paths as per your project structure
import { toast } from "react-toastify";
import Header from "../../component/Header";

export default function WorkingHours() {
  const [rowModesModel, setRowModesModel] = React.useState({});

  const theme = useTheme();
  const dispatch = useDispatch();

  const workingHours = useSelector((state) => state.workingHours.Details);
  const isSuccess = useSelector((state) => state.workingHours.isSuccess);
  //const appErr = useSelector((state) => state.workingHours.appErr);

  const Updated = useSelector((state) => state.workingHours.Updated);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    dispatch(fetchDetailsAction());
  }, [dispatch]);

  useEffect(() => {
    if (workingHours) {
      const data = workingHours.map((item, index) => ({
        ...item,
        id: item.id,
      }));
      setDataSource(data);
    }
  }, [workingHours]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  // useEffect(() => {
  //   if (appErr) {
  //     toast.error(appErr);
  //   }
  // }, [appErr]);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id, newRow) => {
    try {
      dispatch(
        updateDetailsAction({
          data: newRow && newRow,
          id: newRow?._id && newRow?._id,
        })
      );
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

      resetState();
    } catch (error) {
      console.error("Error in handleSaveClick:", error);
    }
  };

  const processRowUpdate = (newRow) => {
    handleSaveClick(newRow && newRow._id, newRow);

    setDataSource(
      dataSource.map((row) => (row._id === newRow?._id ? newRow : row))
    );

    return newRow;
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = dataSource.find((row) => row.id === id);
    if (editedRow.isNew) {
      setDataSource(dataSource.filter((row) => row._id !== id));
    }
  };

  const columns = [
    { field: "id", headerName: "Id", width: 200, editable: false },
    { field: "day", headerName: "Day", width: 200, editable: false },
    { field: "start", headerName: "Start", width: 200, editable: true },
    { field: "close", headerName: "Close", width: 200, editable: true },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
          />,
        ];
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Header title="Working Hours" subtitle="List of Working Hours" />
      </div>
      <Box mt={3} sx={{ backgroundColor: theme.palette.background.default }}>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={dataSource}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
        </div>
      </Box>
    </Box>
  );
}
