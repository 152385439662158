import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  DialogActions,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  addAllergensAction,
  resetState,
} from "../../state/allergens/allergensSlice"; // Update import
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const AddAllergenForm = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSuccess = useSelector((state) => state.allergens.isSuccess);
  const appErr = useSelector((state) => state.allergens.appErr);
  const added = useSelector((state) => state.allergens.added);
  const loading = useSelector((state) => state.allergens.loading);
 
  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Allergen Added Successfully!");
     
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, added,dispatch]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addAllergensAction(values));
      console.log(values);
      formik.resetForm();
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 8000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "35%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="Allergens Details" subtitle="Add Allergen" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Allergen Name :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Name: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddAllergenForm;
