import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Typography,
  Grid,
  DialogActions,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  updateMapsAction,
  fetchDetailAction,
  resetState,
} from "../../state/maps/mapsSlice";

import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const EditMap = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSuccess = useSelector((state) => state.maps.isSuccess);
  const appErr = useSelector((state) => state.maps.appErr);
  const map = useSelector((state) => state.maps.data);
  const Updated = useSelector((state) => state?.maps.Updated);
  const loading = useSelector((state) => state?.maps.loading);

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailAction(id));
    }
  }, [dispatch, id, isSuccess]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    embedMap: Yup.string().required("embedMap is required"),
    directionMap: Yup.string().required("directionMap is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      embedMap: map?.embedMap || "",

      directionMap: map?.directionMap || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        embedMap: values?.embedMap,

        directionMap: values?.directionMap,
      };
      dispatch(updateMapsAction({ data, id }));
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }}
        PaperProps={{
          sx: {
            width: "45%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb="1rem">
              <Header title="Maps" subtitle="Add Map" />
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Map :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    EmbedMap: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="embedMap"
                    name="embedMap"
                    label="EmbedMap"
                    variant="outlined"
                    fullWidth
                    value={formik.values.embedMap}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.embedMap && Boolean(formik.errors.embedMap)
                    }
                    helperText={
                      formik.touched.embedMap && formik.errors.embedMap
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Map Link :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Direction Map: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="directionMap"
                    name="directionMap"
                    label="Direction Map"
                    variant="outlined"
                    fullWidth
                    value={formik.values.directionMap}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.directionMap &&
                      Boolean(formik.errors.directionMap)
                    }
                    helperText={
                      formik.touched.directionMap && formik.errors.directionMap
                    }
                    style={{ marginTop: "25px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditMap;
