import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.BASE_URL }),
  reducerPath: "adminApi",
 
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `users/${id}`,
      
    }),
   
  }),
});

export const {
  useGetUserQuery,
  
} = api;