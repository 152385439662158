import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../utils/config";
const resetEditAction = createAction("terms/reset");

//add action
export const addTermsAction = createAsyncThunk(
  "terms/add",
  async (terms, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    //http call
    try {
      const { data } = await axios.post(`${baseUrl}/termsData/`, terms, config);
      dispatch(fetchTermsAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchTermsAction = createAsyncThunk(
  "terms/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/termsData`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchDetailAction = createAsyncThunk(
  "terms/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/termsData/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateTermsAction = createAsyncThunk(
  "terms/update",
  async ({ data, id }, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // HTTP call
    try {
      const { data: terms } = await axios.put(
        `${baseUrl}/termsData/${id}`,
        { data }, // Use the updated values from the form
        config
      );

      // Dispatch
      dispatch(resetEditAction());
      dispatch(fetchTermsAction());
      return terms;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteTermsAction = createAsyncThunk(
  "terms/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/termsData/${id}`, config);
      dispatch(fetchTermsAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const termsSlices = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addTermsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addTermsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addTermsAction.rejected, (state, action) => {
      //  console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchTermsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTermsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchTermsAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteTermsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTermsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update  state after deletion if needed
    });
    builder.addCase(deleteTermsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateTermsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetEditAction, (state, action) => {
      state.isEdited = true;
    });
    builder.addCase(updateTermsAction.fulfilled, (state, action) => {
      state.Updated = action?.payload;
      state.loading = false;

      console.log(action.payload);
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateTermsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //category details
    builder.addCase(fetchDetailAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDetailAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
  },
});

export default termsSlices.reducer;
