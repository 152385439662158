import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Header from "../../component/Header";
import { useParams } from "react-router-dom";
import {
  updatePrivacyAction,
  fetchDetailAction,
  resetState,
} from "../../state/privacy/privacy"; // Update import statements
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader";

const EditPrivacy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appErr = useSelector((state) => state.privacy.appErr);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchDetailAction(id));
  }, [dispatch, id]);

  const loading = useSelector((state) => state.privacy.loading);
  const data = useSelector((state) => state.privacy.data);
  const isEdited = useSelector((state) => state?.privacy.isEdited);
  const isSuccess = useSelector((state) => state?.privacy.isSuccess);
  const Updated = useSelector((state) => state?.privacy.Updated);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      if (appErr) {
        toast.error(appErr);
      }
    }
  }, [isSuccess, Updated, appErr]);

  /* useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);*/

  // Define validation schema
  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      text: data?.text || "", // Ensure that the initial value is not undefined
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        text: values?.text,
      };
      dispatch(updatePrivacyAction({ data, id }));
      console.log(values);

      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  const handleBack = () => {
    navigate(`/dashboard/privacy/`);
  };
  if (isEdited) return <Navigate to="/dashboard/privacy" />;

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Box m="1.5rem 2.5rem">
          <Button
            style={{
              display: "flex",
              alignItems: "center",

              marginBottom: "2rem",
            }}
            color="primary"
            variant="contained"
            onClick={handleBack}
            size="lg"
            //   startIcon={<AddCircleOutlineIcon />}
          >
            Back
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "30px",
            }}
          >
            <Header
              title="Privacy Policy Details"
              subtitle="Edit Privacy Policy"
            />
          </div>
          <Box
            width="100%"
            mb="1.5rem"
            sx={{
              "& .ql-editor": {
                height: "200px", // Set the height of the editor
                border: "1px solid #ccc", // Example border
                borderRadius: "4px", // Example border radius
                marginTop: "40px",
              },
            }}
          >
            <ReactQuill
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              name="text"
              value={formik.values.text}
              onChange={(value) => formik.setFieldValue("text", value)}
              error={formik.touched.text && Boolean(formik.errors.text)}
            />
            {formik.touched.text && formik.errors.text && (
              <div style={{ color: "red" }}>{formik.errors.text}</div>
            )}
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            {!loading ? "Submit" : <Loader />}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default EditPrivacy;
