import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  Drawer,
  Grid,
  DialogActions,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  updateColorsAction,
  fetchColorAction,
  resetState,
} from "../../state/colors/colorsSlice"; // Update import statements
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ColorPicker from "react-pick-color";
import Loader from "../../component/loader";

const EditColor = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const appErr = useSelector((state) => state.colors.appErr);
  const loading = useSelector((state) => state.colors.loading);
  const color = useSelector((state) => state.colors.data);
  const isSuccess = useSelector((state) => state?.colors.isSuccess);
  const Updated = useSelector((state) => state?.colors.Updated);

  useEffect(() => {
    if (id) {
      dispatch(fetchColorAction(id));
    }
  }, [dispatch, id, isSuccess]);

  const [mainColor, setMainColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [footerColor, setFooterColor] = useState("");
  const [footerBarColor, setFooterBarColor] = useState("");
  const [initialPriceColor, setInitialPriceColor] = useState("");
  const [offerPriceColor, setOfferPriceColor] = useState("");

  useEffect(() => {
    if (color) {
      setMainColor(color.main_color || "");
      setSecondaryColor(color.secondary_color || "");
      setFooterColor(color.footer_color || "");
      setFooterBarColor(color.footer_bar_color || "");
      setInitialPriceColor(color.initial_price_color || "");
      setOfferPriceColor(color.offer_price_color || "");
    }
  }, [color]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);
 
  // Define validation schema
  const validationSchema = Yup.object().shape({
    main_color: Yup.string().required("Main Color is required"),
    secondary_color: Yup.string().required("Secondary Color is required"),
    footer_color: Yup.string().required("Color is required"),
    footer_bar_color: Yup.string().required(" Color is required"),
    initial_price_color: Yup.string().required(" Color is required"),
    offer_price_color: Yup.string().required(" Color is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      main_color: mainColor,
      secondary_color: secondaryColor,
      footer_color: footerColor,
      footer_bar_color: footerBarColor,
      initial_price_color: initialPriceColor,
      offer_price_color: offerPriceColor,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        main_color: values?.main_color,
        secondary_color: values?.secondary_color,
        footer_color: values?.footer_color,
        footer_bar_color: values?.footer_bar_color,
        initial_price_color: values?.initial_price_color,
        offer_price_color: values?.offer_price_color,
      };
      dispatch(updateColorsAction({ data, id }));
      console.log(values);
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }}
        PaperProps={{
          sx: {
            width: "85%", // Default width
            "@media (max-width: 900px)": {
              width: "95%", // Width set to 70% for screens <= 700px
              alignItems: "center",
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 1rem">
            <Box mb="1rem">
              <Header title="Colors Details" subtitle="Add Colors" />
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Main Color:
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Color: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    display="flex" // Apply flexbox layout
                    flexDirection="column" // Align items vertically
                    alignItems="center" // Center items horizontally
                  >
                    <ColorPicker
                      color={mainColor}
                      onChange={(color) => {
                        formik.setFieldValue("main_color", color.hex);
                        setMainColor(color.hex);
                      }}
                      theme={{
                        background: "lightgrey",
                        inputBackground: "grey",
                        borderColor: "darkgrey",
                        // borderRadius: '8px',
                        color: "black",
                        width: "70%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Secondary Color::
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Color: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    display="flex" // Apply flexbox layout
                    flexDirection="column" // Align items vertically
                    alignItems="center" // Center items horizontally
                  >
                    <ColorPicker
                      color={secondaryColor}
                      onChange={(color) => {
                        formik.setFieldValue("secondary_color", color.hex);
                        setSecondaryColor(color.hex);
                      }}
                      theme={{
                        background: "lightgrey",
                        inputBackground: "grey",
                        borderColor: "darkgrey",
                        // borderRadius: '8px',
                        color: "black",
                        width: "70%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Footer Color:
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Color: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    display="flex" // Apply flexbox layout
                    flexDirection="column" // Align items vertically
                    alignItems="center" // Center items horizontally
                  >
                    <ColorPicker
                      color={footerColor}
                      onChange={(color) => {
                        formik.setFieldValue("footer_color", color.hex);
                        setFooterColor(color.hex);
                      }}
                      theme={{
                        background: "lightgrey",
                        inputBackground: "grey",
                        borderColor: "darkgrey",
                        // borderRadius: '8px',
                        color: "black",
                        width: "70%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Footer Bar Color:
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Color: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    display="flex" // Apply flexbox layout
                    flexDirection="column" // Align items vertically
                    alignItems="center" // Center items horizontally
                  >
                    <ColorPicker
                      color={footerBarColor}
                      onChange={(color) => {
                        formik.setFieldValue("footer_bar_color", color.hex);
                        setFooterBarColor(color.hex);
                      }}
                      theme={{
                        background: "lightgrey",
                        inputBackground: "grey",
                        borderColor: "darkgrey",
                        // borderRadius: '8px',
                        color: "black",
                        width: "70%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Initial Price Color:
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Color: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    display="flex" // Apply flexbox layout
                    flexDirection="column" // Align items vertically
                    alignItems="center" // Center items horizontally
                  >
                    <ColorPicker
                      color={initialPriceColor}
                      onChange={(color) => {
                        formik.setFieldValue("initial_price_color", color.hex);
                        setInitialPriceColor(color.hex);
                      }}
                      theme={{
                        background: "lightgrey",
                        inputBackground: "grey",
                        borderColor: "darkgrey",
                        // borderRadius: '8px',
                        color: "black",
                        width: "70%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Offer Price Color:
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Color: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    display="flex" // Apply flexbox layout
                    flexDirection="column" // Align items vertically
                    alignItems="center" // Center items horizontally
                  >
                    <ColorPicker
                      color={offerPriceColor}
                      onChange={(color) => {
                        formik.setFieldValue("offer_price_color", color.hex);
                        setOfferPriceColor(color.hex);
                      }}
                      theme={{
                        background: "lightgrey",
                        inputBackground: "grey",
                        borderColor: "darkgrey",
                        // borderRadius: '8px',
                        color: "black",
                        width: "70%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditColor;
