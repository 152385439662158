import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Typography,
  Grid,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import Header from "../../component/Header";
import {
  addDetailsAction,
  resetState,
} from "../../state/translation/translationSlice"; // Update import
import { fetchContactsAction } from "../../state/contacts/contactsSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const AddForm = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.translation.isSuccess);
  const appErr = useSelector((state) => state.translation.appErr);
  const added = useSelector((state) => state.translation.added);
  const loading = useSelector((state) => state.translation.loading);

  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Data Added Successfully!");
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, added]);

  const contacts = useSelector((state) => state?.contacts?.Details);

  const data = contacts && contacts[0];
  useEffect(() => {
    dispatch(fetchContactsAction());
  }, [dispatch, isSuccess,appErr]);

  const languageArrays = data && data.languages;

  const shortcuts =
    languageArrays && languageArrays?.map((language) => language.shortcut);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Label is required"),
    shortcut: Yup.string().required("Shortcut is required"),
    translatedWord: Yup.string().required(" Translated Word is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      label: "",
      shortcut: "",
      translatedWord: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Convert label and translatedWord to lowercase
      const lowercaseValues = {
        ...values,
        label: values.label.toLowerCase(),
        translatedWord: values.translatedWord.toLowerCase(),
      };
      dispatch(addDetailsAction(lowercaseValues));
      console.log(lowercaseValues);
      formik.resetForm();
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "40%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb="2rem">
              <Header title="Extras Details" subtitle="Add New Extras" />
            </Box>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Label :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Label: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="label"
                    name="label"
                    label="Label"
                    variant="outlined"
                    fullWidth
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    error={formik.touched.label && Boolean(formik.errors.label)}
                    helperText={formik.touched.label && formik.errors.label}
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  mt={3}
                  p={2}
                  mb={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Language :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Shortcut: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Select
                    id="shortcut"
                    name="shortcut"
                    value={formik.values.shortcut}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    error={
                      formik.touched.shortcut && Boolean(formik.errors.shortcut)
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  >
                    {shortcuts?.map((shortcut) => (
                      <MenuItem key={shortcut} value={shortcut}>
                        {shortcut}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  mt={3}
                  p={2}
                  mb={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Translated Word :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Word: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="translatedWord"
                    name="translatedWord"
                    label="Translated Word"
                    variant="outlined"
                    fullWidth
                    value={formik.values.translatedWord}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.translatedWord &&
                      Boolean(formik.errors.translatedWord)
                    }
                    helperText={
                      formik.touched.translatedWord &&
                      formik.errors.translatedWord
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginRight: "5px" }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              size="small"
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </Box>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddForm;
