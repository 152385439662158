import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  Box,
  Button,
  Drawer,
  Typography,
  Grid,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Header from "../../component/Header";
import {
  updateDetailsAction,
  fetchDetailAction,
  resetState,
} from "../../state/navItems/navItems"; // Update import statements
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const EditNavItems = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const appErr = useSelector((state) => state.navItems.appErr);
  const loading = useSelector((state) => state.navItems.loading);
  const data = useSelector((state) => state.navItems.data);
  const isSuccess = useSelector((state) => state?.navItems.isSuccess);
  const Updated = useSelector((state) => state?.navItems.Updated);

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);

  const validationSchema = Yup.object().shape({
    itemToDisplay: Yup.string().required("Item is required"),
    link: Yup.string().required("Link Date is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      itemToDisplay: data?.itemToDisplay || "", // Ensure that the initial value is not undefined
      link: data?.link || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        itemToDisplay: values?.itemToDisplay,
        link: values?.link,
      };
      dispatch(updateDetailsAction({ data, id }));
      console.log(values);
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }}
        PaperProps={{
          sx: {
            width: "45%", // Default width
            "@media (max-width: 700px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb="1rem">
              <Header title="Nav Items Details" subtitle="Edit Details" />
            </Box>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Nav item :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Item: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="itemToDisplay"
                    name="itemToDisplay"
                    label="Nav Item"
                    variant="outlined"
                    fullWidth
                    value={formik.values.itemToDisplay}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.itemToDisplay &&
                      Boolean(formik.errors.itemToDisplay)
                    }
                    helperText={
                      formik.touched.itemToDisplay &&
                      formik.errors.itemToDisplay
                    }
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Nav Item Link : <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Link:
                  </Typography>
                  <TextField
                    id="link"
                    name="link"
                    label="Link"
                    variant="outlined"
                    fullWidth
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    error={formik.touched.link && Boolean(formik.errors.link)}
                    helperText={formik.touched.link && formik.errors.link}
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancel
            </Button>
            {loading ? (
              <Button type="submit" variant="contained" color="secondary">
                Loading please wait...
              </Button>
            ) : (
              <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>
            )}
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default EditNavItems;
