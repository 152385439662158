import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Drawer,
  Typography,
  Grid,
  Button,
  useTheme,
  DialogActions,
} from "@mui/material";
import Header from "../../component/Header";
import { addAboutAction, resetState } from "../../state/aboutus/aboutUsSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/loader";

const AddAboutUs = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSuccess = useSelector((state) => state.aboutUs.isSuccess);
  const added = useSelector((state) => state.aboutUs.added);
  const appErr = useSelector((state) => state.aboutUs.appErr);
  const loading = useSelector((state) => state.aboutUs.loading);

  useEffect(() => {
    if (isSuccess && added) {
      toast.success("Details Added Successfully!");
    }
    if (appErr) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, appErr, added]);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    details: Yup.string().required("Details are required"),
    summary: Yup.string().required("Summary is required"),
  });

  // useFormik hook
  const formik = useFormik({
    initialValues: {
      details: "",
      summary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addAboutAction(values));
      console.log(values);
      formik.resetForm();
      handleClose();
      setSubmitting(false);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ zIndex: "9999 " }} // Default width
        PaperProps={{
          sx: {
            width: "55%", // Default width
            "@media (max-width: 900px)": {
              width: "70%", // Width set to 70% for screens <= 700px
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box m="1.5rem 2.5rem">
            <Box mb={3}>
              <Header title="About Us " subtitle="Add New Details" />
            </Box>
            <hr style={{ border: "1px solid #f0f0f0", margin: "10px 0" }} />
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    About Us Details :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Details: <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextField
                    id="details"
                    name="details"
                    label="Details"
                    variant="outlined"
                    value={formik.values.details}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.details && Boolean(formik.errors.details)
                    }
                    helperText={formik.touched.details && formik.errors.details}
                    style={{ marginTop: "25px", width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  p={2}
                  mt={2}
                  borderRadius={4}
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.shadows[3]}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    About Us Summary :
                  </Typography>
                  <hr
                    style={{ border: "1px solid #f0f0f0", margin: "10px 0" }}
                  />
                  <Typography variant="h6" gutterBottom>
                    Summary: <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="summary"
                    name="summary"
                    aria-label="summary"
                    multiline
                    rows={4}
                    placeholder="Summary"
                    variant="outlined"
                    value={formik.values.summary}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.summary && Boolean(formik.errors.summary)
                    }
                    helperText={formik.touched.summary && formik.errors.summary}
                    style={{
                      width: "100%",
                      marginTop: "25px",
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              
            >
              {!loading ? "Submit" : <Loader />}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
};
export default AddAboutUs;
